import { Box, Text } from '@chakra-ui/react';
import { getIn } from 'formik';
import React from 'react';
import { useDropzone } from 'react-dropzone';
// import cancelIcon from '../../../assets/icons/cancel.svg';

const CustomFileInput = (props) => {
  const {
    field,
    form,
    accept,
    disabled,
    label,
    actionText,
    maxFiles,
    labelTypes,
    onChange,
    fileSizePreferer
  } = props;
  const { name } = field;

  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const isError = !!error && isTouched;

  const [files, setFiles] = React.useState([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    noClick: true,
    maxSize: 100000000,
    maxFiles: maxFiles,
    disabled: disabled,
    onDrop: (acceptedFiles) => {
      const filesUpload = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      setFiles(files.concat(filesUpload));
      form.setFieldValue(field.name, files.concat(filesUpload));
      if (onChange) {
        onChange(filesUpload);
      }
    }
  });

  // const deleteImage = (index) => {
  //   files.splice(index, 1);
  //   setFiles(files);
  //   form.setFieldValue(field.name, files);
  // };

  // const thumbs = files.map((file, index) => (
  //   <Box key={index} position="relative">
  //     <Box
  //       background={`url(${cancelIcon}) no-repeat`}
  //       width="20px"
  //       height="20px"
  //       position="absolute"
  //       right="20px"
  //       onClick={() => deleteImage(index)}
  //     ></Box>
  //     {/* <DeleteIcon onClick={() => deleteImage(index)} /> */}

  //     <Box
  //       key={file.name}
  //       display="inline-flex"
  //       borderRadius="2px"
  //       border="1px solid #eaeaea"
  //       marginBottom="8px"
  //       marginRight="20px"
  //       padding="4px"
  //       boxSizing="border-box"
  //     >
  //       <Box display="flex" minWidth="0" overflow="hidden">
  //         <Box
  //           src={file.preview}
  //           alt=""
  //           display="block"
  //           width="auto"
  //           height="100%"
  //         />
  //       </Box>
  //     </Box>
  //   </Box>
  // ));

  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      <Box pb={6}>
        <label htmlFor="" className="form__label">
          {label}
        </label>
        <Box
          mt={4}
          {...getRootProps({ className: 'dropzone' })}
          isError={isError}
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderWidth="1px"
          borderRadius="5px"
          borderStyle="dashed"
          borderColor={isError ? '#E53E3E' : '#858585'}
          color="#858585"
          outline="none"
          transition="border 0.24s ease-in-out"
          minHeight="100px"
          maxHeight="336px"
          justifyContent="center"
          opacity={disabled === 'disabled' ? 0.75 : 1}
          {...disabled === 'disabled' ? { pointerEvents: 'none' } : null}
        >
          <input id={name} name={name} {...getInputProps()} />
          <Box display="flex" flexFlow=" column">
            <Box onClick={open} color="#1b39ce" cursor="pointer">
              <Text color="white" textAlign="center">
                {actionText ?? 'Click to upload file'}{' '}
              </Text>
            </Box>
            <Text align="center">
              {labelTypes
                ? labelTypes
                : 'JPG, JPEG, PNG, GIF, WEBP, MP4. Max 100mb.'}
            </Text>
            {fileSizePreferer && (
              <Text align="center" fontSize={12}>
                {fileSizePreferer}
              </Text>)}
          </Box>

          {/* <Box display="flex" flexDirection="row" flexWrap="wrap" marginTop="16" padding="20px">
            {thumbs}
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default CustomFileInput;
