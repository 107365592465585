import React from 'react';
import BalanceHistory from './balance_history';
import TransactionHistory from './transaction_history';
import './style.css';
import LineBalance from './line_balance';
import TopContributerTokenPie from './pie_top_contributer';

const Graphics = ({ address }) => {
  return (
    <>
      <div className="analytics" data-category="analytics">
        <div className="analytics__box">
          <BalanceHistory />
          <TransactionHistory />
          <LineBalance />
          <TopContributerTokenPie />
        </div>
      </div>
    </>
  );
};

export default Graphics;
