import {
  Box,
  Button,
  chakra,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import axios from 'axios';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Error from '../../ui-kit/Error';
import CustomInput from '../../ui-kit/Input/index';
import { BACKEND_BASE_URL } from '../../utils/constants';
import { authProfile } from '../../utils/requestApi/profile';
import ProfileSchema from './validation';

const EditProfileModal = ({ isOpen, onClose, address, setNickname, currentNickname, profile, getProfileInfo }) => {
  const [formData, setFormData] = useState('');
  const [isAvailible, setIsAvailible] = useState(true);

  const updateUserProfile = async () => {
    const { nickname, avatarImage } = formData.values;

    // let image = '';
    // if (avatarImage) {
    //   const avatarBasename = formData.values.avatarImage.name.replace(/[^a-zA-Z0-9.]+/g, '');
    //   const nftFile = renameFile(formData.values.avatarImage, avatarBasename);

    //   const nftData = new FormData();
    //   nftData.append('file', nftFile);
    //   const responseNftImage = await addFile(nftData);
    //   image = 'ipfs://' + getIpfsPath(responseNftImage, avatarBasename);
    // } else {
    //   image = '';
    // }
    const isAvailibleNickname = await checkAvailibilityNickname(nickname);
    // console.log('isAvailibleNickname', isAvailibleNickname);

    const data = new FormData();
    data.append('nickname', nickname);
    if (avatarImage) {
      data.append('avatar', avatarImage);
    }

    if (isAvailibleNickname || currentNickname === nickname) {
      setIsAvailible(true);

      try {
        const response = await updateProfile(data);
        if (response.status === 200) {
          getProfileInfo();
          setNickname(nickname);
          onClose();
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem('authToken');
          await authProfile({ userAddress: profile ? profile.address : address });
          await updateProfile(data);
          getProfileInfo();
          onClose();
          formData.actions.setSubmitting(false);
          console.log('e', err);
        }
      }
    } else {
      setIsAvailible(false);
      formData.actions.setSubmitting(false);
    }
  };

  const updateProfile = async (data) => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Sign: localStorage.getItem('authToken'),
      },
    };
    const response = await axios.put(`${BACKEND_BASE_URL}/users`, data, config);
    return response;
  };

  const checkAvailibilityNickname = async (nickname) => {
    const response = await axios.get(`${BACKEND_BASE_URL}/users/nicknameAvailable?nickname=${nickname}`);
    return response.data.data.available;
  };

  useEffect(() => {
    if (formData !== '') updateUserProfile();
  }, [formData]);

  const onSubmitHandler = React.useCallback((values, actions, errors) => {
    setFormData({ values, actions });
  }, []);

  const initialValues = {
    nickname: profile ? profile.nickname : '',
    avatarImage: '',
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <Formik initialValues={initialValues} validationSchema={ProfileSchema} onSubmit={onSubmitHandler}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldError,
            onChange,
          }) => (
            <>
              <form onSubmit={handleSubmit} className="update-profile">
                <ModalContent background={"#3F3F43"}>
                  <ModalHeader color={"white"}>Edit profile</ModalHeader>
                  <ModalCloseButton color={"white"} />
                  <ModalBody>
                    <Box display={'flex'} flexFlow="column" gridGap={4} pb={4}>
                      <Image
                        w={'96px'}
                        h={'96px'}
                        borderRadius={6}
                        src={`${values.avatarImage ? URL.createObjectURL(values.avatarImage) : profile &&
                          profile.avatar && profile.avatar_type
                          ? `data:${profile.avatar_type};base64,` + profile.avatar
                          : 'https://cdn.fb.ru/user_ava/198590/main/f.jpg?2907164'
                          }`}
                      />

                      <chakra.span fontSize={'12px'} color={"white"}>
                        We recommend an image of at least 300x300. Gifs work too. Max 5mb.
                      </chakra.span>
                      <Field
                        id="avatarImage"
                        component={({ field, ...props }) => (
                          <Box>
                            <label>
                              <Input
                                type="file"
                                name={field.name}
                                id={field.name}
                                onChange={(event) => {
                                  console.log('avatar', event.currentTarget.files[0]);
                                  setFieldValue('avatarImage', event.currentTarget.files[0]);
                                }}
                                style={{ display: 'none' }}
                                onBlur={field.onBlur}
                              />
                              <chakra.span
                                backgroundColor={'green.500'}
                                p={'10px 20px'}
                                borderRadius={6}
                                color="white"
                                cursor={'pointer'}
                              >
                                Choose file
                              </chakra.span>
                            </label>
                          </Box>
                        )}
                        name="avatarImage"
                        value={values.avatarImage}
                        autoComplete="off"
                        type="text"
                        label="Avatar Image"
                      />
                    </Box>

                    <Field
                      id="nickname"
                      component={CustomInput}
                      type="text"
                      name="nickname"
                      label="Display name"
                      value={values.nickname}
                      autoComplete="off"
                      placeholder="Enter your display name"
                    />
                    {errors.nickname && touched.nickname && <Error>{errors.nickname}</Error>}
                    {!isAvailible && <Error>Nickname is taken by another user </Error>}
                  </ModalBody>
                  <ModalFooter justifyContent={'start'}>
                    {/* <Button onClick={onClose} mr={3} colorScheme="red" variant="solid" bg="red">
                      Cancel
                    </Button> */}
                    <Button
                      type="submit"
                      id="updateProfile"
                      disabled={isSubmitting}
                      variant="solid"
                      color="white"
                      colorScheme="green"
                      isLoading={isSubmitting}
                      loadingText="Updating... Please wait"
                    >
                      Update profile
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default EditProfileModal;
