import { useEffect, useState } from 'react';
import Leaderboard from './leaderboard';
import Nfts from './nfts';
import Contributions from './contribitions';
import Tokens from './tokens';
import Voting from './voting';
import Graphics from './Graphics';

const AccountAssets = ({ setTotalBalance, address, id, walletStatus }) => {

  const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search).toString());
  const [prevQueryParams, setPrevQueryParams] = useState(null);

  useEffect(() => {

    console.log(queryParams)
    //Переключение по навигации
    let nav_items = document.querySelectorAll('[data-target]');
    let contents = document.querySelectorAll('[data-category]');
    for (var item of nav_items) {
      item.addEventListener('click', function () {
        for (var item of nav_items) {
          item.classList.remove('active');
        }
        for (item of contents) {
          item.hidden = true;
        }
        
        this.classList.add('active');
        updateQueryParams('page', this.dataset.target)
        let content = document.querySelector(`[data-category="${this.dataset.target}"]`);
        content.hidden = false;
      });
    }




    //Вывод при наведении на иконку инфо
    let info = document.querySelector('#info');
    var text_info = info.textContent;

    info.addEventListener('mouseover', function () {
      let info_table = document.createElement('div');
      info_table.classList.add('info');
      info_table.style.whiteSpace = 'break-spaces'
      info_table.style.background = 'white'
      info_table.style.zIndex = '20'
      info_table.textContent = info.textContent;
      info.append(info_table);
    });
    info.addEventListener('mouseout', function () {
      this.innerHTML = `<i>${text_info}</i>`;
    });
  }, []);


  const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = {};

    for (const [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    return paramsObject
  };

  const updateQueryParams = (key, value) => {
    const newParams = new URLSearchParams(window.location.search);
    newParams.set(key, value);
    window.history.replaceState(null, '', `?${newParams.toString()}`);
    setQueryParams(newParams.toString());
  };


  useEffect(()=>{
    const params = getQueryParams()
    if(params){
      const currentPageParam = params['page']



      let nav_items = document.querySelectorAll('[data-target]');
      let contents = document.querySelectorAll('[data-category]');


      if(currentPageParam){
        nav_items.forEach(nav_item => {
          const currentDataTarget = nav_item.getAttribute('data-target')
          
          if(currentPageParam !== currentDataTarget){
            nav_item.classList.remove('active');
          }else{
            nav_item.classList.add('active');
          }
        });


        contents.forEach(content => {
          const currentDataCategory = content.getAttribute('data-category')

          if(currentPageParam !== currentDataCategory){
            content.classList.remove('active');
            content.hidden = true;
          }else{
            content.classList.add('active');
            content.hidden = false;
          }
        })


        

      }
      

      // for (var item of nav_items) {
      //   item.addEventListener('click', function () {
      //     for (var item of nav_items) {
      //       item.classList.remove('active');
      //     }
      //     for (item of contents) {
      //       item.hidden = true;
      //     }
          
      //     this.classList.add('active');
      //     updateQueryParams('page', this.dataset.target)
      //     let content = document.querySelector(`[data-category="${this.dataset.target}"]`);
      //     content.hidden = false;
      //   });
      // }

    }
  },[])

  useEffect(() => {
    const handlePopState = () => {
      setQueryParams(new URLSearchParams(window.location.search).toString());
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
    window.removeEventListener('popstate', handlePopState);
    };
}, []);

  return (
    <>
      <div className="navigation">
        <div className="container">
          <div className="navigation__body">
            <ul className="navigation__items">
              <li className="navigation__item active" data-target="analytics">
                Analytics
              </li>
              {/* <li className="navigation__item " data-target="community-pool">
                Community pool
              </li> */}
              <li className="navigation__item " data-target="tokens">
                Tokens
              </li>
              <li className="navigation__item " data-target="nft">
                NFTs
              </li>
              <li className="navigation__item " data-target="contributions">
                Contributions
              </li>
              <li className="navigation__item " data-target="leaderboard">
                Leaderboard{' '}
                <span className="navigation__item-icon" id="info">
                  <i>The leaderboard is a rating of the most active participants of this DAP. The top 3 contributors can receive an NFT as a reward!</i>
                </span>
              </li>
              <li className="navigation__item " data-target="voting">
                Voting
              </li>
              
            </ul>
          </div>
          {/* <div className="content">
        <div className="container">
          <div className="content__body">
            <Graphics address={address}></Graphics>
            <Tokens setTotalBalance={setTotalBalance} address={address} walletStatus={walletStatus} />
            <Nfts address={address} />
            <Contributions address={address} />
            <Leaderboard id={id} />
            <Voting id={id} />
            <CommunityPool id={id} />

          </div>
        </div>
      </div> */}
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="content__body">
            <Graphics address={address}></Graphics>
            <Tokens setTotalBalance={setTotalBalance} address={address} walletStatus={walletStatus} />
            <Nfts address={address} />
            <Contributions address={address} />
            <Leaderboard id={id} />
            <Voting id={id} />
             {/* <CommunityPool id={id} /> */}

          </div>
        </div>
      </div>
    </>
  );
};

export default AccountAssets;
