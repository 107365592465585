import { Tooltip } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import styles from './style.module.css'


export const CustomButton = ({props}) =>{



    // return props.type === 'main' && (
    //     <button 
    //         className={`
    //             ${styles.button} 
    //             ${props?.className ? props?.className : ''}
    //             ${props?.theme ? styles[props.theme] : styles.primary}
    //         `}
    //         onClick={props?.onClick}
    //     >
    //         {props.label}
    //     </button>
    // )

    if(props?.isLink){
        return <a
            className={`
                ${styles.crcl_animate} 
                
                ${props?.theme ? styles[props.theme] : styles.primary}
                ${props?.disabled && styles.disabled}
                ${props?.className ? props.className : ''}
            `}

            
        // {props?.isLink ? `href="${props?.onClick}"` :`onClick=${props?.onClick}` }
            href={props?.onClick}
        >
    {/* // className={styles.crcl_animate}> */}
            <div className={styles.crcl_animate_inner} style={{...props?.styles}}>
                <>
                    <span className={styles.crcl_animate_main_label}>
                        {props.label}
                    </span>
                    <span className={styles.crcl_animate_hover_label}>
                        {/* {props?.hoverLabel ? props?.hoverLabel: props.label} */}
                        {props?.tooltip ? <Tooltip label="Upload your scientific data to demo.privateai.com in to apply for the community rewards" aria-label='A tooltip'>
                            {props?.hoverLabel ? props?.hoverLabel: props.label}
                        </Tooltip> : (props?.hoverLabel ? props?.hoverLabel: props.label)}
                    </span>
                </>
            </div>
        </a>
    }

    return <>
        <button
             className={`
                ${styles.crcl_animate} 
                ${props?.disabled && styles.disabled}
                ${props?.className ? props?.className : ''}
                ${props?.theme ? styles[props.theme] : styles.primary}
            `}
            // {props?.isLink ? `href="${props?.onClick}"` :`onClick=${props?.onClick}` }
            onClick={props?.onClick}
        >
        {/* // className={styles.crcl_animate}> */}
            <div className={styles.crcl_animate_inner}>
                <>
                    <span className={styles.crcl_animate_main_label}>
                        {props.label}
                    </span>
                    <span className={styles.crcl_animate_hover_label}>
                        {/* {props?.hoverLabel ? props?.hoverLabel: props.label} */}
                        {props?.tooltip ? <Tooltip label="Upload your scientific data to demo.privateai.com in to apply for the community rewards" aria-label='A tooltip'>
                            {props?.hoverLabel ? props?.hoverLabel: props.label}
                        </Tooltip> : (props?.hoverLabel ? props?.hoverLabel: props.label)}
                    </span>
                </>
                
                {/* {props.label} */}
                {/* {props?.hoverLabel} */}
            </div>
        </button>
    </>
}