import { Textarea } from '@chakra-ui/react';
import { getIn } from 'formik';

function CustomTextarea({ field, form, label, description, onBlur, ...props }) {
  const { name, value, ...fieldProps } = field;

  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const isError = !!error && isTouched;

  return (
    <>
      <label htmlFor="" className="form__label">
        {label}

        <Textarea
          color="white"
          border={'none'}
          name={name}
          value={value === null ? '' : value}
          {...fieldProps}
          {...props}
          isInvalid={isError}
          onBlur={onBlur}
          resize="none"
          background={'#515156'}
          marginBottom={"24px"}
        />
      </label>
    </>
  );
}

export default CustomTextarea;
