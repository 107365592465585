import * as yup from 'yup';

const regMatch =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
const regex = /(https?:\/\/(.+?\.)?archon.talkyard\.net(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/g;

const WalletSchema = yup.object().shape({
  address: yup.string().required('Required'),
  avatar: yup.array().required('Required'),
  name: yup.string().min(2, 'Too Short!').max(300, 'Maximum of 20 characters').required('Required'),
  description: yup.string().min(2, 'Too Short!').max(300, 'Maximum of 300 characters').required('Required'),
  twitter: yup.string(),
  projectUrl: yup.string().required('Required').matches(regMatch, 'Website should be a valid URL'),
  forumUrl: yup.string().matches(regex, 'Website should be a valid URL'),
  category: yup.number().required('Required')
});

export default WalletSchema;
