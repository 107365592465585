import { useState } from 'react';
import { Box, Table, TableCaption, Text } from '@chakra-ui/react';

import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useParams } from 'react-router-dom';
import { getTokenBalance } from '../../../utils/requestApi/wallet';
import NumberFormat from 'react-number-format';
import Pagination from '../../../ui-kit/Pagination';
const pageLimit = 10;

const Tokens = ({ setTotalBalance, address, walletStatus }) => {
  const { id } = useParams();
  const { chainId, account } = useWeb3React();
  const [tokensInfo, setTokensInfo] = useState({ tokens: null, totalTokens: 0 });
  const [pagination, setPagination] = useState({
    currentTokens: null,
    currentPage: 1,
    totalPages: null,
    totalCount: null,
  });

  const getAccountTokens = async () => {
    const response = await getTokenBalance({ id, page: pagination.currentPage });
    const { pie, total_count, total } = response.data;
    setTokensInfo({ tokens: Object.entries(pie), totalTokens: parseInt(total_count) });
    setTotalBalance(total);
  };

  useEffect(() => {
    if (walletStatus === 'READY')
      getAccountTokens();
  }, [account, chainId, walletStatus]);

  const onPageChanged = async (data) => {
    const { currentPage, totalPages } = data;
    const offset = (currentPage - 1) * pageLimit;
    const response = await getTokenBalance({ id, page: currentPage });
    const { pie, total_count, } = response.data;
    setTokensInfo({ tokens: Object.entries(pie), totalTokens: parseInt(total_count) });
    const currentTokens = Object.entries(pie).tokens.slice(offset, offset + pageLimit);

    setPagination({
      currentTokens,
      currentPage,
      totalPages,
      totalCount: parseInt(total_count),
    });
  };

  return (
    <>
      <Table className="table" data-category="tokens" hidden>
        {walletStatus === 'READY' ?
          <>
            {tokensInfo.tokens && tokensInfo.tokens.length !== 0 ? <>
              <thead className="table__head">
                <tr className="table__row">
                  {/* <td className="table__column">#</td> */}
                  <td className="table__column">Tokens</td>
                  <td className="table__column">Value</td>
                </tr>
              </thead>
              <tbody className="table__body">

                {tokensInfo.tokens &&
                  tokensInfo.tokens.map(([key, item]) => {
                    return (
                      <tr className="table__row" key={key}>
                        <td className="table__column">{key}</td>
                        <td className="table__column"><NumberFormat value={parseFloat(item.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      </tr>
                    );
                  })}
              </tbody>
              {tokensInfo.totalTokens > pageLimit && (
                <TableCaption height="55px" width="100%" paddingInlineEnd={0}>
                  <Pagination
                    totalRecords={tokensInfo.totalTokens}
                    pageLimit={pageLimit}
                    pageNeighbours={0}
                    onPageChanged={onPageChanged}
                  />
                </TableCaption>
              )}</> : <Box color="white">There are no tokens in the wallet</Box>}

          </>
          : (<tr className="table__row no-data_container" ><Text color={"white"}>Getting data in progress... Please wait.</Text></tr>)}
      </Table>

    </>
  );
};

export default Tokens;
