import axios from 'axios';
import { COVALENTHQ_API_KEY, COVALENTHQ_BASE_URL, BACKEND_BASE_URL, BACKEND_GNOSIS_URL } from '../constants';

export const getBalance = async ({ chainId, account }) => {
  const response = await axios.get(
    `${COVALENTHQ_BASE_URL}/${chainId || 1
    }/address/${account}/balances_v2/?quote-currency=USD&format=JSON&nft=false&no-nft-fetch=false&key=${COVALENTHQ_API_KEY}`
  );
  return response.data;
};

export const getTransactions = async ({ id, page }) => {
  const response = await axios.get(`${BACKEND_BASE_URL}/wallets/${id}/transactions?page-number=${page}`);
  return response.data;

  // const response = await axios.get(
  //   `${COVALENTHQ_BASE_URL}/${
  //     chainId || 1
  //   }/address/${account}/transactions_v2/?quote-currency=USD&format=JSON&block-signed-at-asc=false&no-logs=false&key=${COVALENTHQ_API_KEY}`
  // );
  // return response.data;
};

// https://api.covalenthq.com/v1/1/address/0x9173BE58bF265c40385c6B3c4F8e80873c1255FD/transactions_v2/?quote-currency=USD&format=JSON&block-signed-at-asc=false&no-logs=false&key=ckey_21ca00c471dd46d4bc632744f67

export const getMultiSigAddresses = async ({ account }) => {
  const response = await axios.get(
    `${BACKEND_GNOSIS_URL}/v1/chains/5/owners/${account}/safes`
  );
  return response.data;
};
