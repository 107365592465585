import { Box, Button, Flex } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ReactComponent as CloseImg } from '../../../assets/icons/close.svg';
import { canvasPreview, imgPreview } from './canvas';

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function CropAvatarImage({ values, setFieldValue, error, touched, image, fieldName, isAddMode, setIsBase64Image }) {
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [aspect, setAspect] = useState(21 / 5);
  const [approveCrop, setApproveCrop] = useState(false);

  useEffect(() => {
    if (image) {
      console.log('image', image);
      onSelectFile(image);
    }
  }, [image]);

  function onSelectFile(uploadedImage) {
    setCrop(undefined); // Makes crop preview update between images.
    const reader = new FileReader();
    reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
    if (uploadedImage) {
      reader.readAsDataURL(uploadedImage);
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 21 / 5));
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.

        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);
      }
    },
    100,
    [completedCrop, scale]
  );

  const approve = async () => {
    const files = [];
    const croppedFile = await imgPreview(imgRef.current, completedCrop, scale, image);

    setFieldValue(
      fieldName,
      files.concat(
        Object.assign(croppedFile, {
          preview: URL.createObjectURL(croppedFile),
        })
      )
    );
    setIsBase64Image(false);
    setApproveCrop(true);
  };

  return (
    <>
      <Box>


        <label htmlFor="" className="form__label">
          Upload avatar
        </label>
        <Box display="flex" flexFlow="column" alignItems={"center"}>
          {Boolean(imgSrc) && !approveCrop && (
            <>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
              </ReactCrop>
              <Button variant="solid" bg="green.500" colorScheme="green" onClick={() => approve()} mb={2} mt={2} >
                Crop avatar image
              </Button>
            </>
          )}
        </Box>

        <Flex w="full" h="full" flexDirection="column" alignContent="center" >
          <Box pb={2} display={"flex"} justifyContent="center" flexDirection="column" alignItems="center">
            {Boolean(completedCrop) && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  // border: '1px solid black',
                  // borderRadius: '12px',
                  objectFit: 'fill',
                  width: completedCrop.width,
                  height: completedCrop.height,
                  // width: completedCrop.width,
                  // height: completedCrop.height,
                }}
              />
            )}
            {/* <Flex color="gray.900" justifyContent="space-between" alignItems="center">
              <Box pr={4}>{image.name}</Box>
              <CloseImg
                onClick={() => {
                  setFieldValue(fieldName, '');
                  setApproveCrop(false);
                }}
              />
            </Flex> */}

            <Flex color="gray.900" justifyContent="space-between" alignItems="center" pt={2}>
              <Box pr={4} color="white">
                {isAddMode ? values.avatar[0].name : fieldName}
              </Box>
              <CloseImg onClick={() => setFieldValue(fieldName, '')} />
            </Flex>

            {/* <UploadedCoverImageFullSize src={imgSrc} borderRadius={4} /> */}
          </Box>
        </Flex>
      </Box>
    </>
  );
}
