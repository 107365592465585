import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN, CREATE_WALLET } from '../routings';
import { BACKEND_BASE_URL } from '../constants';

export default function useAuth() {
  let navigate = useNavigate();
  const [error, setError] = useState(null);

  const loginUser = async ({ data, onError }) => {
    const { username, password } = data;
    try {
      const response = await axios.post(`${BACKEND_BASE_URL}/auth/`, {
        login: username,
        password: password,
      });

      if (response.status === 200) {
        localStorage.setItem('jwtToken', response.data.token);
        navigate(CREATE_WALLET);
      } else {
        onError();
        setError('Something wrong, try again');
      }

      // if (username === '1' && password === '1') {
      //   // localStorage.setItem('jwtToken', response.data.token);
      //   localStorage.setItem('jwtToken', '123456');
      //   navigate(HOME);
      //   // window.location.replace(HOME);
      // } else {
      //   onError();
      //   // return { error: true, message: 'Something wrong' };
      //   setError('Something wrong, try again');
      // }
    } catch (e) {
      localStorage.removeItem('jwtToken');
      navigate(LOGIN, { replace: true });
      setError({ error: true, message: 'Something wrong' });
    }

    // return axios
    //   .post(`${backendUrl}sojasfojasfs/`, {
    //     Username: username,
    //     Password: password,
    //   })
    //   .then(async (response) => {
    //     localStorage.setItem('jwtToken', response.data.token);
    //     window.location.replace('/');
    //   })
    //   .catch((err) => {
    //     localStorage.removeItem('jwtToken');
    //     history.push('auth/login');
    //     setError('Error');
    //   });
  };

  return {
    loginUser,
    error,
  };
}
