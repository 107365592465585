import { useEffect, useState } from 'react';
import './style.css';
import { getTokenBalance, getTransactionHistory, getWalletCategories, getWallets } from '../../utils/requestApi/wallet';
import { Link, useNavigate } from 'react-router-dom';
import { LOGIN, WALLET } from '../../utils/routings';
import Avatar from '../../assets/images/eth-avatar.png';
import TwitterImg from '../../assets/img/twitter.png';
import ProjectImg from '../../assets/img/project.png';
import ForumImg from '../../assets/img/forum.png';
import MyCustomSelect from '../../ui-kit/CustomField/CustomSelect';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import {
  Menu,
  chakra,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Image,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons';
import SortSrc from '../../assets/icons/sorting_white.png';
import Loading from '../../components/Loader';
import { useWeb3React } from '@web3-react/core';
import LikeIcon from '../WalletInfo/like';
import SelectCategory from './categories';
import goldBG from '../../assets/video/gold.mp4'
import { Footer } from '../../components/Footer';
import { CustomButton } from '../../components/Button';

import styles from './styles.module.css'
import AccountAssets from '../WalletInfo/AccountAssets';
import NumberFormat from 'react-number-format';
import { useCallback } from 'react';
import { CategoryRow } from '../../components/CategoryRow';
import CommunityPoolGraphic from '../WalletInfo/AccountAssets/Graphics/community_pool';
import { CategoryItem } from '../../components/CategoryRow/CategoryItem';
import marketplace_img from '../../assets/images/marketplace-rewards.svg'
import marketplace_icon from '../../assets/images/marketplace-rewards-icon.svg'



const selectOptions = [
  { value: 'desc', label: 'desc' },
  { value: 'asc', label: 'asc' },
];

function Wallets({ unapprovedOnly, isAdmin = false }) {
  let navigate = useNavigate();
  const [wallets, setWallets] = useState();
  const [updatedWallets, setUpdatedWallets] = useState();
  const [sort, setSort] = useState();
  const [categories, setCategories] = useState();
  const [filterCategoty, setFilterCategoty] = useState();
  const { account, chainId, active } = useWeb3React();
  const [isAsideOpen, setAsideFlag] = useState(false);
  const [isShowGraphic , setShowGraphic] = useState(false);
  const [loading , setLoading] = useState(false);

  const getCategories = async () => {
    const response = await getWalletCategories();
    const selectOptions = [];

    response.data.map((item) => {
      selectOptions.push({ value: item.id, label: item.name });
    });

    setCategories(selectOptions.reverse());
  }

  useEffect(() => {
    getWalletList(filterCategoty);
  }, [filterCategoty]);


  useEffect(() => {
    getWalletList();
  }, [sort,account]);

  useEffect(() => {
    getCategories();
  }, []);


  const getAccountTokens = async (id) => {
    const response = await getTokenBalance({ id, page: 1 });
    const { total } = response.data;
    return total || 0
    // setTokensInfo({ tokens: Object.entries(pie), totalTokens: parseInt(total_count) });
    // setTotalBalance(total);
  };

  async function getWalletTransactionHistory(id, period) {
    const {data} = await getTransactionHistory({ id, period });


    let newData = data.reverse()

    for (let i = newData.length - 1; i >= 0 ; i--) {
      const element = newData[i];
      if(element.incoming || element.outcoming) {
        // console.log(id, element)
        return element;
      }
    }


  };



  const addDataToWallets = async (arr) =>{

    await arr.forEach(
      async (item ,i ) =>{
        item['total'] = 0
       
        const _active =  await getWalletTransactionHistory(item.id) || null
        const _total = await getAccountTokens(item.id) || Number(0)
        
        item['total'] = _total
        item['activity'] = _active

        // if(i === wallets.length - 1){
        //   setUpdatedWallets(wallets)
        // }

         
      }
    )
    setUpdatedWallets(arr)
    
  }


  useEffect(()=>{
    if(wallets){
      // addDataToWallets(wallets)
      // console.log(wallets)
      // console.log(categories)
    }
  },[wallets])


  const getWalletList = async (category = '') => {
    setLoading(true) 
    try {
      
      let {data} = await getWallets({ unapprovedOnly: unapprovedOnly || false, sort, userAddress: account, category });

      setWallets(data) 
      setLoading(false) 
    } catch (e) {
      // console.log(e);
      if (e.response.status === 401) {
        localStorage.removeItem('jwtToken');
        navigate(LOGIN);
      }
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    function myFunction() {
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = (winScroll / height) * 100;
      return scrolled
    }
    window.addEventListener('scroll',()=>{
      const gradients = document.querySelectorAll('.gradient')
      if(gradients){
        gradients.forEach(gradient =>{

          gradient.style.transform = 'translateY('+ (-100 + myFunction() * 5) + 'px)'
        })
      }
      
    })
  },[])

  const handleSort = (e) => {
    console.log('sort', e)
    setLoading(() => false)
    setSort(e);

};


  return (
    <>
      {/* {wallets  ? ( */}
      
      <div className="" style={{position:'absolute', width:"100%", height: "100%", left: 0,top:0, overflow:'hidden', zIndex:-1}}>

      
        <div className="gradient-2 gradient" style={{bottom:'98%', top:'auto'}}></div>
        <div className="gradient-1 gradient" style={{bottom:'98%', top:'auto'}}></div>
        <div className="gradient-5 gradient" style={{bottom:'90%', top:'auto'}}></div>

        {categories && <>
        
        
        
        
          <div className="gradient-2 gradient" style={{bottom:'68%', top:'auto'}}></div>
          <div className="gradient-1 gradient" style={{bottom:'68%', top:'auto'}}></div>
          <div className="gradient-5 gradient" style={{bottom:'70%', top:'auto'}}></div>

          <div className="gradient-2 gradient" style={{bottom:'46%', top:'auto'}}></div>
          <div className="gradient-1 gradient" style={{bottom:'46%', top:'auto'}}></div>
          <div className="gradient-5 gradient" style={{bottom:'48%', top:'auto'}}></div>

          <div className="gradient-2 gradient" style={{bottom:'22%', top:'auto'}}></div>
          <div className="gradient-1 gradient" style={{bottom:'22%', top:'auto'}}></div>
          <div className="gradient-5 gradient" style={{bottom:'24%', top:'auto'}}></div>

        </>}
      </div>

        <div className="wallets" >
          <div className="container">
            <Box className="wallets__body" padding={isAdmin ? '0' : "128px 0 63px 0"}>

            <div className="marketplace-rewards" style={{flexWrap:'wrap'}}>
                <img className='marketplace-rewards__icon'  src={marketplace_icon} alt="marketplace-rewards-icon" />

                <div className="col">
                  <img className='marketplace-rewards__img'  src={marketplace_img} alt="marketplace-rewards" />
                  <h3 className="marketplace-rewards__title">Community pool</h3>
                  <p className="marketplace-rewards__rewards-value">
                     $ 10 000
                  </p>
                  <p className="marketplace-rewards__rewards-desc">
                  Treasury balance
                  </p>
                </div>
                <div className={`col ${styles.btnCol}`} style={{marginLeft: 'auto', marginTop: 'auto', marginRight: 0}}>
                  <CustomButton
                    props={{
                      theme: 'primary',
                      label: 'Claim rewards',
                      hoverLabel : 'Contribute',
                      className : 'red',
                      isLink: true,
                      onClick: "https://demo.privateai.com/",
                      tooltip : "Upload your scientific data to demo.privateai.com in to apply for the community rewards"
                    }}
                  />
                  <CustomButton
                    props={{
                      theme: 'primary',
                      label: 'View',
                      // hoverLabel : 'Contribute',
                      className : 'red',
                      onClick: () => {
                        setShowGraphic(prev=> !prev)
                      },
                      // tooltip : "Upload your scientific data to demo.privateai.com in to apply for the community rewards"
                    }}
                  />
                </div> 
                <div className={`${isShowGraphic ? styles.active: styles.unactive} ${styles.graphicWrap}` } style={{width:'100%'}}>
                  <div className="" style={{width:'100%'}}>
                    <CommunityPoolGraphic id={0} />
                  </div>
                </div>
              </div>              
              

              {!isAdmin && <Heading 
              // fontSize={"33.9px"} 
              fontSize={"4.4vmax"}
              className="page_header"
              // textAlign={'center'}
               fontFamily={"Satoshi"}  color="white"
              //  pt={'50px'}
                // mb={'13px'}  pb={'20px'}
                // p={'0vh 0px 10vh 0 '}
                mb={'10vh'}
                textAlign={'center'}
                ><chakra.span textDecoration={'underline'}
              // color="#ffffff"
               >Explore</chakra.span>&nbsp;DeSci DAOs, transactions, balances and social connections</Heading>}


              {/* <div className={`wallet__grid-content ${isAsideOpen && "active" }`}>
                <aside className={`wallet_grid-aside`}>
                  <div className="wallet_grid-aside_sticky-wrap">

                  <Button
                    height={'auto'}
                    id="token"
                    type="submit"
                    // disabled={isSubmitting}
                    variant="solid"
                    // mt={0}
                    bg="green.500"
                    colorScheme="black"
                    fontWeight={700}
                    // isLoading={isSubmitting}
                    loadingText="Submitting"
                    display="flex"
                    align-items="center"
                    justify-content=" center"
                    background="transparent"
                    color="#FFFFFF"
                    // border={"2px solid rgba(39, 62, 93, 1)"}
                    _hover={{
                      background:"transparent",
                    }}
                    _active={{
                      background:"transparent"
                    }}
                    mt={"-2px"}
                    mb={"24px"}
                    // p={'5px'}
                    ml={"auto"}
                    onClick={()=>{
                      setAsideFlag(prev => !prev)
                    }}
                    // margin-top="60px"
                    // borderRadius="100px;"
                    className="form__button aside_btn"
                    >
                      {
                        isAsideOpen ? 
                          <svg width="34" height="34" viewBox="0 0 667 667" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M309.333 251L227 333.333L309.333 415.667C311.789 417.955 313.759 420.715 315.126 423.782C316.492 426.849 317.227 430.159 317.286 433.516C317.345 436.873 316.728 440.207 315.471 443.32C314.213 446.433 312.342 449.261 309.968 451.635C307.594 454.009 304.766 455.88 301.653 457.137C298.54 458.395 295.206 459.012 291.849 458.953C288.492 458.894 285.182 458.159 282.115 456.793C279.049 455.426 276.289 453.456 274 451L174 351C169.318 346.312 166.688 339.958 166.688 333.333C166.688 326.708 169.318 320.354 174 315.667L274 215.667C276.289 213.21 279.049 211.24 282.115 209.874C285.182 208.508 288.492 207.773 291.849 207.714C295.206 207.654 298.54 208.272 301.653 209.529C304.766 210.787 307.594 212.658 309.968 215.032C312.342 217.406 314.213 220.234 315.471 223.347C316.728 226.46 317.345 229.794 317.286 233.151C317.227 236.508 316.492 239.818 315.126 242.885C313.759 245.951 311.789 248.711 309.333 251Z" fill="rgba(39, 62, 93, 1)"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M58.3333 0C42.8624 0 28.0251 6.14582 17.0854 17.0854C6.14582 28.0251 0 42.8624 0 58.3333V608.333C0 640.533 26.1333 666.667 58.3333 666.667H608.333C623.804 666.667 638.642 660.521 649.581 649.581C660.521 638.642 666.667 623.804 666.667 608.333V58.3333C666.667 42.8624 660.521 28.0251 649.581 17.0854C638.642 6.14582 623.804 0 608.333 0H58.3333ZM50 58.3333C50 56.1232 50.878 54.0036 52.4408 52.4408C54.0036 50.878 56.1232 50 58.3333 50H433.333V616.667H58.3333C56.1232 616.667 54.0036 615.789 52.4408 614.226C50.878 612.663 50 610.543 50 608.333V58.3333ZM483.333 616.667V50H608.333C610.543 50 612.663 50.878 614.226 52.4408C615.789 54.0036 616.667 56.1232 616.667 58.3333V608.333C616.667 610.543 615.789 612.663 614.226 614.226C612.663 615.789 610.543 616.667 608.333 616.667H483.333Z" fill="rgba(39, 62, 93, 1)"/>
                          </svg>
                          : <svg width="34" height="34" viewBox="0 0 667 667" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M174.646 415.667L256.979 333.333L174.646 251C172.19 248.711 170.22 245.951 168.853 242.885C167.487 239.818 166.752 236.508 166.693 233.151C166.634 229.794 167.251 226.46 168.508 223.347C169.766 220.234 171.637 217.406 174.011 215.032C176.385 212.658 179.213 210.787 182.326 209.529C185.439 208.272 188.773 207.654 192.13 207.714C195.487 207.773 198.797 208.508 201.864 209.874C204.93 211.24 207.69 213.21 209.979 215.667L309.979 315.667C314.661 320.354 317.291 326.708 317.291 333.333C317.291 339.958 314.661 346.312 309.979 351L209.979 451C207.69 453.456 204.93 455.426 201.864 456.793C198.797 458.159 195.487 458.894 192.13 458.953C188.773 459.012 185.439 458.395 182.326 457.137C179.213 455.88 176.385 454.009 174.011 451.635C171.637 449.261 169.766 446.433 168.508 443.32C167.251 440.207 166.634 436.873 166.693 433.516C166.752 430.159 167.487 426.849 168.853 423.782C170.22 420.715 172.19 417.955 174.646 415.667Z" fill="rgba(39, 62, 93, 1)"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M58.3333 0C42.8624 0 28.0251 6.14582 17.0854 17.0854C6.14582 28.0251 0 42.8624 0 58.3333V608.333C0 640.533 26.1333 666.667 58.3333 666.667H608.333C623.804 666.667 638.642 660.521 649.581 649.581C660.521 638.642 666.667 623.804 666.667 608.333V58.3333C666.667 42.8624 660.521 28.0251 649.581 17.0854C638.642 6.14582 623.804 0 608.333 0H58.3333ZM50 58.3333C50 56.1232 50.878 54.0036 52.4408 52.4408C54.0036 50.878 56.1232 50 58.3333 50H433.333V616.667H58.3333C56.1232 616.667 54.0036 615.789 52.4408 614.226C50.878 612.663 50 610.543 50 608.333V58.3333ZM483.333 616.667V50H608.333C610.543 50 612.663 50.878 614.226 52.4408C615.789 54.0036 616.667 56.1232 616.667 58.3333V608.333C616.667 610.543 615.789 612.663 614.226 614.226C612.663 615.789 610.543 616.667 608.333 616.667H483.333Z" fill="rgba(39, 62, 93, 1)"/>
                          </svg>
                      }
                    </Button>
                    
                    
                    {!isAdmin && false && <Box
                      display={"flex"} 
                      gap={"20px"}
                      flexDirection={"column"}
                      mb={"24px"}
                    >

                      <h2 className="wallets__title">
                        <span id="wallets__number">{wallets.length}</span> Wallets
                      </h2>
                    </Box>
                    }
                    <Flex flexFlow={"column"} alignItems={"center"} pb={8}>
                      <Box justifyContent={"center"} w={'100%'} >
                        <MyCustomSelect onChange={(e) => { setFilterCategoty(e.value) }} options={categories} />
                      </Box>
                    </Flex>
                  </div>
                </aside>
                <div className="wallets__list-outer">
                <div className="wallets__list-wrap">
                  <div className="wallets__list">
                    {wallets.map((item, index) => (
                      <WalletItem data={item} key={item.id} />
                    ))}
                  </div>
                </div>
                </div>
                
              </div> */}
             


              {isAdmin && <div className="wallet__row">
                <div className="wallet__head">
                  <div className="wallet__left_col">
                      <h2 className="wallet__head_title">
                        { categories && categories.find(cat => cat.value === filterCategoty)?.label || 'All categories'}
                        <span>
                          <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.905 47.812C36.96 47.812 47.811 36.984 47.811 23.906C47.811 10.851 36.936 0 23.881 0C10.804 0 0 10.851 0 23.906C0 36.984 10.827 47.812 23.905 47.812ZM23.906 43.828C12.842 43.828 4.005 34.968 4.005 23.906C4.005 12.866 12.818 3.984 23.881 3.984C34.92 3.984 43.802 12.867 43.826 23.906C43.849 34.969 34.943 43.828 23.904 43.828" fill="black"/>
                            <path d="M25.6606 26.2971C25.6376 27.5151 25.0046 28.1481 23.8806 28.1481C22.7086 28.1481 22.0516 27.4921 22.0286 26.2971L21.7236 13.8981C21.7006 12.7031 22.5906 11.8361 23.8566 11.8361C25.0986 11.8361 26.0366 12.7261 26.0126 13.9221L25.6606 26.2971Z" fill="black"/>
                            <path d="M26.4106 33.3281C26.4106 34.6871 25.2156 35.7651 23.8806 35.7651C22.5206 35.7651 21.3486 34.6641 21.3486 33.3281C21.3486 31.9681 22.4976 30.8901 23.8806 30.8901C25.2396 30.8901 26.4106 31.9451 26.4106 33.3281Z" fill="black"/>
                          </svg>

                        </span>
                      </h2>
                      
                  </div>
                  <div className="wallet__right_col">
                      <ul className="wallet__btn_list">
                        <li className="wallet__btn create_dao_btn" 
                          onClick={()=>{
                            navigate('/wallet/create')
                          }}
                        >
                          <svg width="15" height="15" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.15152 10V0H5.84848V10H4.15152ZM0 5.84848V4.15152H10V5.84848H0Z" fill="#fff"/> 
                          </svg>Create DAO
                        </li>
                        {/* <li>
                          <Menu>
                            <MenuButton 
                              height={38} as={Button} colorScheme="teal" color="black" background={'linear-gradient(83deg, #fff, #fff)'}  _hover={{ bg: 'linear-gradient(83deg, #fff, #fff)', opacity: 0.8 }} _active={{ bg: 'linear-gradient(83deg, #fff, #fff)' }}>
                              <Flex alignItems="center">
                                <Image w={4} h={4} src={SortSrc} color="black" mr={2}></Image>
                                <Text fontSize={'xm'} 
                                display={{  md: 'flex' }} justifyContent={'center'} w={"100%"} color="#000" 
                                padding={'0 5px'}
                                >
                                  Sort & Filter
                                </Text>
                              </Flex>
                            </MenuButton>
                            <MenuList minWidth="240px" zIndex="99999">
                              <MenuOptionGroup
                                defaultValue={'ALL'}
                                title="Sort & Filter"
                                type="radio"
                                onChange={(value) => handleSort(value)}
                              >
                                <MenuItemOption color="black"  value="">Default</MenuItemOption>
                                <MenuItemOption  color="black"  value="tx_sort asc">Last activity: Latest first</MenuItemOption>
                                <MenuItemOption color="black"  value="tx_sort desc">Last activity: Oldest first  </MenuItemOption>
                                <MenuItemOption color="black"  value="balance_sort asc">Balance: Greater First</MenuItemOption>
                                <MenuItemOption  color="black"   value="balance_sort desc">Balance: Smaller First  </MenuItemOption>
                              </MenuOptionGroup>
                            </MenuList>
                          </Menu>
                        </li> */}
                        <li>
                        <MyCustomSelect onChange={(e) => { setFilterCategoty(e.value) }} options={categories} />

                        </li>
                      </ul>
                  </div>
                </div>


                <div className="wallet_content" style={{paddingBottom: 120}}>
                  <div className="wallet_content_list">
                      {loading && 'Loading...'}
                      {wallets && !loading && wallets.map(wallet =>{
                        return  <CategoryItem data={wallet} key={wallet.id} />
                      })}
                    {/* {renderList(updatedWallets || [])} */}
                  </div>
                </div>

              </div>}

              {
                !isAdmin && categories && categories.map(category => <CategoryRow 
                  props={{
                    category,
                    unapprovedOnly,
                    account
                  }}
                />)
              }


              
              

            </Box>
          </div>
        </div>
      {/* ) : <Box 
        h={'100vh'}
        minH={'600px'}
        display={'flex'}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Loading />
      </Box>
      } */}
      {
        !isAdmin && <>
          {/* <p className="bottom_to_top vertical__text">DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;</p> */}
          {/* <p className="top_to_bottom vertical__text">DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;</p> */}
        </>
      }
      <Footer />
    </>
  );
}

export default Wallets;

export function WalletItem({ data, showLikeIcon = true }) {
  const { account, active } = useWeb3React();
  const [totalBalance, setTotalBalance] = useState(0);

  const getAccountTokens = async () => {
    const response = await getTokenBalance({ id: data.id, page: 1 });
    const { pie, total_count, total } = response.data;
    // setTokensInfo({ tokens: Object.entries(pie), totalTokens: parseInt(total_count) });
    setTotalBalance(total);
  };

  useEffect(()=>{
    getAccountTokens()
  },[data])
  // console.log(data);

  return (
    // <div className="wallets__item-wrap">

    
     <Link to={`${WALLET}/${data.id}`} className="wallets__item">
         <div className="wallets__item-inner">
          <div className="wallets_item_left-col">
            <div className="wallets_item_left-col_featured-img">
            
              <Image src={data.avatar && data.avatar_type
                ? `data:${data.avatar_type};base64,` + data.avatar
                : Avatar} alt="" className="wallets__image wallets__item-block" 
                w={"100%"} 
                // w={"73px"} height={"73px"} minW={73} 
                borderRadius={10}
               />
                 
            </div>
            <div className="wallets__item-social">
              {data?.projects[0] && <a href={data.projects[0]}><Image src={ProjectImg} w={"18px"} h={"18px"} /></a>}
              {data?.projects[1] && <a href={data.projects[1]}><Image src={TwitterImg} w={"18px"} h={"18px"} /></a>}
              {data?.projects[2] && <a href={data.projects[2]}><Image src={ForumImg} w={"18px"} h={"18px"} /></a>}
            </div>
          </div>
          <div className="wallets__item-block">
            <p className="wallets__item-title"><chakra.span>{data.name.length > 20 ? data.name.slice(0, 15) + '...' : data.name} </chakra.span>  {active && data.is_liked && <LikeIcon id={data.id} address={account} status={data.is_liked} size="4" />}</p>
            <h3 className="wallets__item-price"><NumberFormat value={parseFloat(totalBalance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>

            {/* <div className="wallets__item-social">
              {data.projects[0] && <a href={data.projects[0]}><Image src={ProjectImg} w={"20px"} h={"20px"} /></a>}
              {data.projects[1] && <a href={data.projects[1]}><Image src={TwitterImg} w={"20px"} h={"20px"} /></a>}
              {data.projects[2] && <a href={data.projects[2]}><Image src={ForumImg} w={"20px"} h={"20px"} /></a>}
            </div> */}
            <p className="wallets__item-description">
              {data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description}
              </p>
            
          </div >
        </div>

        

      </Link>
      
  );
}

