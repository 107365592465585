import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Button,
  Box,
  chakra,
  SimpleGrid
} from '@chakra-ui/react'
import { useEffect } from 'react';

function InfoModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" >
        <ModalOverlay />
        <ModalContent 
        mt={'auto'} mb={'auto'} 
        backgroundColor={"#4D4E58"} padding={'56px'} maxH={'90vh'} overflow={'auto'}>
          <Box color={"white"} fontSize={"45px"} fontWeight="700" lineHeight={"48px"} textAlign="center" >
            You are about to create a <chakra.span color="#fff" textDecoration={'underline'}>Privacy DAO</chakra.span>
          </Box>

          <SimpleGrid pt={"50px"} gridGap={"40px"}>
            <Box>
              <Box display={"flex"} flexFlow="row" gridGap={"14px"}>
                <Box>
                  <Box fontSize={"30px"} className='icon-hand' />
                </Box>
                <Box color={"white"} fontWeight="700" fontSize={"24px"}>
                  Switching Networks
                </Box>
              </Box>
              <Box fontWeight={"400"} color="white" fontSize={"16px"}> Before proceedint, make sure you are connected to the correct network. Currently the creation of multi-signature wallets is supported on the Goerli network.
                If you don't have Goerli network tokens, you can get them by following the link: <a href='https://goerlifaucet.com/' target={"_blank"} rel="noreferrer"  style={{ color: "#fff" ,textDecoration:'underline', fontWeight: 600 }}>https://goerlifaucet.com/</a> </Box>
            </Box>

            <Box>
              <Box display={"flex"} flexFlow="row" gridGap={"14px"}>
                <Box>
                  <Box fontSize={"30px"} className='icon-tablet' />
                </Box>
                <Box color={"white"} fontWeight="700" fontSize={"24px"}>
                  PrivateAI Forum
                </Box>
              </Box>
              <Box fontWeight={"400"} color="white" fontSize={"16px"}> The forum provides DAOs with a convenient way to communicate and interact with their community. Each project is required to own at least a single forum thread. Create one for your project in the next step.</Box>
            </Box>

            <Box>
              <Box display={"flex"} flexFlow="row" gridGap={"14px"}>
                <Box>
                  <Box fontSize={"30px"} className='icon-cube' />
                </Box>
                <Box color={"white"} fontWeight="700" fontSize={"24px"}>
                  Multi-Sig Wallet
                </Box>
              </Box>
              <Box fontWeight={"400"} color="white" fontSize={"16px"}> In order to create a DAO token in the future, manage it and a multi-signature wallet, you need to create a wallet using the Create button located under the Address field.
                In the occurring modal window, you must enter the addresses of the future owners of the wallet and threshold*. Your currently connected address will be used automatically as one of the owners' addresses.</Box>
              <Box fontWeight={"400"} color="white" fontSize={"16px"} pt={4}>*Threshold - the number of signatures of wallet owners that will need to be collected in order to conduct a transaction from a multisignature wallet. (For example, if there are 5 owners and threshold = 3, then in order to make a transaction, it will need to be signed by any 3 owners</Box>
            </Box>

            <Box align={"center"} display={"flex"} justifyContent="center">
              <Button onClick={onClose} variant="solid"
                h={"48px"}
                m={0}
                _hover={{ bg: "#ffffff00" }}
                fontWeight={700}
                loadingText="Submitting"
                display="flex"
                align-items="center"
                justify-content=" center"
                background="#00000000"
                color="#FFFFFF"
                border={'1px solid #fff'}
                borderRadius="100px;"
                className="form__button icon-shell">
                I Understand
              </Button>
            </Box>
          </SimpleGrid>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InfoModal;