
import { CustomButton } from '../Button'
import styles from './style.module.css'

export const Footer = () =>{

    return (
        <>
            <footer className={styles.footer}>
                <div className={styles.footer__inner}>
                    <div className="container">
                        <h2 className={styles.footer__h2}>
                            [ Let's Collaborate ]
                        </h2>
                        <h3 className={styles.footer__h3}>
                            Try our demo to see it in action.
                        </h3>
                        <p className={styles.footer__p}>
                            Securely share your data to change the world for the best.
                        </p>
                        <div className={styles.footer__btn_container}>
                            <CustomButton
                                props={{
                                    isLink:true,
                                    label:"$PGPT MARKETS",
                                    hoverLabel:"COMING SOON",
                                    disabled:true,
                                    onClick: null
                                }}
                            />
                            <CustomButton
                                props={{
                                    className:styles.demoBtn,
                                    styles:{borderWidth:0},
                                    isLink:true,
                                    label:"DEMO",
                                    onClick: "https://demo.privateai.com/",
                                    theme: 'secondary'
                                }}
                            />
                        </div>
                        <hr className={styles.footer__divider} />
                        <div className={styles.footer__bottom}>
                            <p className={styles.footer__p2}>
                                Web3 Foundation LLC. 2024. All rights reserved.
                            </p>
                            <ul className={styles.footer__nav_list}>
                                {/* <li className={styles.footer__list_item}>
                                    <a href="#">
                                        Legal
                                    </a>
                                </li>
                                <li className={styles.footer__list_item}>
                                    <a href="#">
                                        Branding Guide
                                    </a>
                                </li>
                                <li className={styles.footer_list_divider}>|</li> */}
                                <li className={styles.footer__list_item}>
                                    <a target={'_blank'} href="https://www.linkedin.com/company/privateai/">
                                        LinkedIn
                                    </a>
                                </li>
                                <li className={styles.footer__list_item}>
                                    <a target={'_blank'} href="https://twitter.com/privateaicom">
                                        Twitter
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}