import { Image, Text } from "@chakra-ui/react";
import SommingSoonImg from '../../../assets/images/comming-soon.png';

function Voting() {
  return <table className="table" data-category="voting" hidden>
    <tr className="table__row comming no-data_container">
      <svg height="96px" viewBox="0 0 109 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M85.7433 21.584C93.37 26.3019 99.8468 32.6954 104.658 40.2495C99.7306 47.986 93.0563 54.5051 85.1891 59.2537C82.2924 63.1623 78.5368 66.4925 74.1712 69.0166C88.4089 64.4094 100.449 54.9374 108.322 42.5745C109.226 41.1557 109.226 39.3433 108.322 37.9245C100.742 26.0214 89.2987 16.7982 75.7507 12.0166C79.7198 14.5783 83.12 17.831 85.7433 21.584ZM32.2663 12.3727C19.1479 17.2477 8.07423 26.3104 0.677684 37.9245C-0.225895 39.3433 -0.225895 41.1557 0.677684 42.5745C8.36356 54.6429 20.0199 63.9565 33.8151 68.6791C29.5138 66.0908 25.8306 62.71 23.0125 58.7632C15.4918 54.0603 9.1018 47.7229 4.34234 40.2495C8.98898 32.9533 15.1897 26.7398 22.4796 22.0731C25.0288 18.287 28.3599 14.9905 32.2663 12.3727Z" fill="white"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M78.7416 30.3551C76.0027 23.4503 70.3911 17.7305 62.8256 15.096C49.1167 10.3223 34.1435 17.473 29.213 31.0818L24.615 31.1493C24.7347 30.7563 24.8627 30.3639 24.999 29.9724C30.5985 13.8921 48.1733 5.39574 64.2536 10.9952C73.6131 14.2543 80.4033 21.5705 83.3402 30.2876L78.7416 30.3551Z" fill="white"></path>
        <path d="M30.8466 34.3097C29.979 36.801 27.2562 38.1174 24.7649 37.2498C22.2736 36.3823 20.9572 33.6594 21.8247 31.1681C22.6922 28.6768 25.4151 27.3605 27.9064 28.228C30.3977 29.0955 31.7141 31.8184 30.8466 34.3097Z" fill="white"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.0006 48.7856C31.7395 55.6904 37.3511 61.4102 44.9166 64.0447C58.6255 68.8183 73.5987 61.6676 78.5292 48.0588L83.1272 47.9914C83.0075 48.3844 82.8795 48.7768 82.7432 49.1683C77.1437 65.2486 59.5689 73.7449 43.4886 68.1455C34.1291 64.8864 27.3389 57.5702 24.402 48.8531L29.0006 48.7856Z" fill="white"></path>
        <path d="M76.8956 44.8309C77.7632 42.3396 80.486 41.0233 82.9773 41.8908C85.4686 42.7583 86.785 45.4812 85.9175 47.9725C85.0499 50.4638 82.3271 51.7801 79.8358 50.9126C77.3445 50.0451 76.0281 47.3222 76.8956 44.8309Z" fill="white"></path><path fillRule="evenodd" clipRule="evenodd" d="M54.5 61.0166C65.8218 61.0166 75 51.6146 75 40.0166C75 28.4186 65.8218 19.0166 54.5 19.0166C43.1782 19.0166 34 28.4186 34 40.0166C34 51.6146 43.1782 61.0166 54.5 61.0166ZM61.1786 34.3551C61.1786 36.8805 59.831 39.0835 57.8322 40.2506L59.4886 50.1229C59.668 51.1922 58.8641 52.1693 57.8049 52.1693H51.0878C50.0286 52.1693 49.2247 51.1922 49.4041 50.1229L51.0814 40.1264C49.1955 38.9314 47.939 36.7936 47.939 34.3551C47.939 30.6099 50.9028 27.5738 54.5588 27.5738C58.2148 27.5738 61.1786 30.6099 61.1786 34.3551Z" fill="white"></path>
      </svg>
      COMING SOON
      {/* <Image src={SommingSoonImg} />  */}
    </tr>
  </table>;
}

export default Voting;