import { DeleteIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import { deleteWalletCategory } from '../../../utils/requestApi/wallet';

function DeleteModal({ id, updateCategoryList }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = async () => {
    try {
      const response = await deleteWalletCategory(id);
      if (response.data.deleted) {
        updateCategoryList();
        onClose();
      }
    } catch (e) {
      console.log('error', e);
    }
  }

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}
      <DeleteIcon onClick={onOpen} cursor="pointer" />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Do you realy want to delete this category?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='teal' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost' onClick={handleDelete}>Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteModal;