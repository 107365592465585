export const COVALENTHQ_BASE_URL = 'https://api.covalenthq.com/v1';
export const COVALENTHQ_API_KEY = 'ckey_21ca00c471dd46d4bc632744f67';

export const BACKEND_BASE_URL = 'https://desciback.hashlab.online/api/v1';
export const BACKEND_GNOSIS_URL = 'https://gnosis.backend.hashlab.online/cgw';
export const BACKEND_REWARD_BASE_URL = 'https://nft.rewards.hashlab.online/api/v1';
export const MULTISIG_BASE_URL = "https://multisig.archon.network";
export const GOOGLE_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSd9o-4o9sloqWfTi9uCxUuS3RoOWdE3crR4k0FFME3lfBeqdw/viewform';

export const REWARDS_CONTRACT_ADDRESS = '0x7d0af8dc5D6cB1bb52B445EB71a12991Ac275DAC';

export const gnosisContractAddress = '0xA4ddd3B070D86c77ce033EDABE8EBB754c8dd0E4';
export const daoFactoryAddress = '0x66ddA6ffa5db231E3eAdF3062CBb2A81EaaD8bE4';
export const zeroAddress = '0x0000000000000000000000000000000000000000';

export const networks = [
  { id: 1, name: 'Ethereum', network: 'ethereum' },
  // { id: 1280, name: 'MoonRabbit EVM', network: 'moonRabbit' },
  { id: 56, name: 'BNB Chain', network: 'binanceSmartChain' },
  // { id: 1313161554, name: 'Aurora', network: 'aurora' },
  // { id: 4, name: 'Rinkeby', network: 'rinkeby' },
  { id: 5, name: 'Goerli', network: 'goerli' }
];

export const pieBackgroundColor = [
  // 'rgb(3, 17, 13, 0.8)',
  // 'rgb(4, 26, 20, 0.8)',
  // 'rgb(6, 35, 26), 0.8',
  'rgb(7, 44, 33, 0.8)',
  'rgb(8, 52, 40, 0.8)',
  'rgb(10, 61, 46, 0.8)',
  'rgb(11, 70, 53, 0.8)',
  'rgb(13, 78, 59, 0.8)',
  'rgb(14, 87, 66, 0.8)',

  'rgb(15, 96, 73, 0.8)',
  'rgb(17, 104, 79, 0.8)',
  'rgb(18, 113, 86, 0.8)',
  'rgb(20, 122, 92, 0.8)',
  'rgb(21, 131, 99, 0.8)',
  'rgb(22, 139, 106, 0.8)',
  'rgb(24, 148, 112, 0.8)',
  'rgb(25, 157, 119, 0.8)',
  'rgb(27, 165, 125, 0.8)',

  'rgb(39, 178, 138, 0.8)',
  'rgb(51, 182, 144, 0.8)',
  'rgb(62, 186, 150, 0.8)',
  'rgb(73, 190, 157, 0.8)',
  'rgb(85, 194, 163, 0.8)',
  'rgb(96, 198, 169, 0.8)',
  'rgb(107, 202, 175, 0.8)',
  'rgb(119, 206, 181, 0.8)',
  'rgb(130, 210, 187, 0.8)',

  'rgb(142, 215, 194, 0.8)',
  'rgb(153, 219, 200, 0.8)',
  'rgb(164, 223, 206, 0.8)',
  'rgb(176, 227, 212, 0.8)',
  'rgb(187, 231, 218, 0.8)',
  'rgb(198, 235, 224, 0.8)',
  'rgb(210, 239, 230, 0.8)',
  'rgb(221, 243, 237, 0.8)',
  'rgb(232, 247, 243, 0.8)',
];

export const pieBorderColor = [
  // 'rgb(3, 17, 13)',
  // 'rgb(4, 26, 20)',
  // 'rgb(6, 35, 26)',
  'rgb(7, 44, 33)',
  'rgb(8, 52, 40)',
  'rgb(10, 61, 46)',
  'rgb(11, 70, 53)',
  'rgb(13, 78, 59)',
  'rgb(14, 87, 66)',

  'rgb(15, 96, 73)',
  'rgb(17, 104, 79)',
  'rgb(18, 113, 86)',
  'rgb(20, 122, 92)',
  'rgb(21, 131, 99)',
  'rgb(22, 139, 106)',
  'rgb(24, 148, 112)',
  'rgb(25, 157, 119)',
  'rgb(27, 165, 125)',

  'rgb(39, 178, 138)',
  'rgb(51, 182, 144)',
  'rgb(62, 186, 150)',
  'rgb(73, 190, 157)',
  'rgb(85, 194, 163)',
  'rgb(96, 198, 169)',
  'rgb(107, 202, 175)',
  'rgb(119, 206, 181)',
  'rgb(130, 210, 187)',

  'rgb(142, 215, 194)',
  'rgb(153, 219, 200)',
  'rgb(164, 223, 206)',
  'rgb(176, 227, 212)',
  'rgb(187, 231, 218)',
  'rgb(198, 235, 224)',
  'rgb(210, 239, 230)',
  'rgb(221, 243, 237)',
  'rgb(232, 247, 243)',

]