import React, { useState } from 'react';
import { useEffect } from 'react';

import placeholder from '../../../assets/images/placeholder.jpg';

const CustomImage = ({ props}) => {

    const {metadata, item} = props

  const [imageSrc, setImageSrc] = useState()



  useEffect(()=>{
    setImageSrc(getImageSrc(metadata) || '')
    
    // const src = getImageSrc(metadata) || 'https://imgholder.ru/300x300/8493a8/adb9ca&text=NFT+&font=kelson'
    // setImageSrc(prev => src)
  },[])

  const handleImageError = () => {
    setImageSrc(placeholder)
    // setImageSrc('https://imgholder.ru/300x300/8493a8/adb9ca&text=NFT+&font=kelson');
  };

  const getImageSrc = (metadata) => {
    const src = metadata?.image_full_s3 ? metadata?.image_full_s3 : metadata?.image;
    
    if (src && src.indexOf('ipfs://ips') === 0) {
      return src.replace('ipfs://ips', 'https://ipfs.io/ipfs/')
    }
    else if (src && src.indexOf('ipfs://') === 0) {
      return src.replace('ipfs://', 'https://ipfs.io/ipfs/')
    }
    else if (metadata?.image_url) {
      return metadata.image_url;
    } else {
      // return false
    }    
    // return src
    // console.log(imageExists(src))
    return src
  }

  return (
    <img
      src={imageSrc}
      alt={item.name}
      onError={handleImageError}
      style={{
        // marginBottom: 26
        // width: '100%',
        borderRadius: '16px',
        // Add any other styles as needed
      }}
    />
  );
};

export default CustomImage;