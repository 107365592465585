import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import Error from "../../ui-kit/Error";
import CustomInput from "../../ui-kit/Input";
import { TokenSchema } from "./validation";
import { ethers } from "ethers";
import daoFactoryJson from '../../assets/abi/DAOFactory.json'
import { daoFactoryAddress } from "../../utils/constants";

const TokenForm = ({ multisingAddress, getTokenAddress }) => {
  const [formData, setFormData] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialValues = {
    multisingAddress: multisingAddress,
    name: '',
    symbol: '',
    startPrice: ''
  };

  const onSubmitHandler = useCallback((values, actions) => {
    setFormData({ values, actions });
  }, []);


  const handleToken = async () => {
    const { name, symbol, startPrice, multisingAddress } = formData.values;
    const startPriceWei = ethers.utils.parseUnits(startPrice.toString(), 'ether')

    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const signer = provider.getSigner();
    const abi = daoFactoryJson.abi;
    const daoContract = new ethers.Contract(daoFactoryAddress, abi, signer);

    try {
      const result = await daoContract.createDAOToken(name, symbol, startPriceWei, multisingAddress);
      let receipt = await result.wait();

      if (receipt) {
        console.log('receipt', receipt);
        await getTokenAddress();
        onClose()
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const onError = () => {
    formData.actions.setSubmitting(false);
  };

  useEffect(() => {
    if (formData !== '') handleToken();
  }, [formData]);

  return (
    <>
      <Button background={"#ffffff"} colorScheme="teal"
        variant="solid" onClick={onOpen}>Create token</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered background={"#ffffff"}>
        <ModalOverlay />
        <ModalContent background={"#29292E"}>
          <ModalHeader color={"white"}>Create token</ModalHeader>
          <ModalCloseButton color={"white"} />
          <ModalBody pb={"40px"}>
            <Formik
              initialValues={initialValues}
              validationSchema={TokenSchema}
              validateOnChange={false}
              onSubmit={onSubmitHandler}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <>
                  <form onSubmit={handleSubmit} className="form form__token">
                    <div className="form__body form__token-create">

                      <Field
                        id="multisingAddress"
                        component={CustomInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="multisingAddress"
                        value={values.multisingAddress}
                        autoComplete="off"
                        label="Multisig Address"
                        placeholder="Fill name multising address"
                      />
                      {errors.multisingAddress && touched.multisingAddress && <Error>{errors.multisingAddress}</Error>}

                      <Field
                        id="name"
                        component={CustomInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="name"
                        value={values.username}
                        autoComplete="off"
                        label="Name"
                        placeholder="Fill name token"
                      />
                      {errors.name && touched.name && <Error>{errors.name}</Error>}

                      <Field
                        id="symbol"
                        component={CustomInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="symbol"
                        value={values.symbol}
                        autoComplete="off"
                        label="Symbol"
                        placeholder="Fill token symbol"
                      />
                      {errors.symbol && touched.symbol && <Error>{errors.symbol}</Error>}

                      <Field
                        id="startPrice"
                        component={CustomInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="startPrice"
                        value={values.startPrice}
                        autoComplete="off"
                        label="Start Price"
                        placeholder="Fill token startPrice"
                      />
                      {errors.startPrice && touched.startPrice && <Error>{errors.startPrice}</Error>}

                      {/* {error && <Box color="red.400">{error}</Box>} */}

                      <Button
                        height={'40px'}
                        id="token"
                        type="submit"
                        disabled={isSubmitting}
                        variant="solid"
                        bg="green.500"
                        colorScheme="black"
                        fontWeight={700}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                        display="flex"
                        align-items="center"
                        justify-content=" center"
                        background="#ffffff"
                        color="#FFFFFF"
                        margin-top="60px"
                        borderRadius="100px;"
                        className="form__button icon-rorschach"
                      >
                        Create token
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TokenForm;