import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ethers, utils } from "ethers";
import { Field, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import Error from "../../ui-kit/Error";
import CustomInput from "../../ui-kit/Input";
import { ExchangeTokenSchema, TokenPriceSchema } from "./validation";
import daoTokenJson from '../../assets/abi/DAOToken.json'
import { mintTokens } from "../../utils/contractsApi";
import { useWeb3React } from "@web3-react/core";

const ExchangeTokenForm = ({ tokenAddress, tokenPrice }) => {
  const [formData, setFormData] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { account, chainId } = useWeb3React();
  const [accountBalance, setAccountBalance] = useState(0);
  const [error, setError] = useState('');
  const toast = useToast();

  const initialValues = {
    amount: 10
  };

  const onSubmitHandler = useCallback((values, actions) => {
    setFormData({ values, actions });
  }, []);

  useEffect(() => {
    getAccountBalance();
  }, []);

  const handleExchangeToken = async () => {
    const { amount } = formData.values;

    try {
      console.log('handleExchangeToken: mintTokens');
      const response = await mintTokens({ address: tokenAddress, tokenPrice, amount, onError, cb });
      console.log('response', response);
      onClose();
    } catch (e) {
      formData.actions.setSubmitting(false);
    }
  };

  const cb = () => {
    console.log('cb');
    toast({
      position: 'top-right',
      render: () => (
        <Box color="white" p={3} bg="green.500">
          Your exchange was successful!
        </Box>
      )
    })
  };


  const onError = () => {
    formData.actions.setSubmitting(false);
  };

  const getAccountBalance = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const balance = await provider.getBalance(account);
    // let receipt = await balance.wait();
    console.log('ethers.utils.formatEther(balance.toString()', ethers.utils.formatEther(balance.toString()));
    setAccountBalance(ethers.utils.formatEther(balance.toString()));
  }

  useEffect(() => {
    if (formData !== '') {
      setError('');
      if (parseFloat(accountBalance) >= formData.values.amount * tokenPrice) {
        handleExchangeToken();
      } else {
        setError('Insufficient funds');
        formData.actions.setSubmitting(false);
      }

    };
  }, [formData]);

  return (
    <>
      <Button background={"#ffffff"} colorScheme="teal"
        variant="solid" onClick={onOpen}>Exchange tokens</Button>
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered background={"#ffffff"}>
        <ModalOverlay />
        <ModalContent background={"#29292E"}>
          <ModalHeader color={"white"}>Exchange tokens </ModalHeader>
          <ModalCloseButton color={"white"} />
          <ModalBody pb={"40px"}>
            <Formik
              initialValues={initialValues}
              validationSchema={ExchangeTokenSchema}
              validateOnChange={false}
              onSubmit={onSubmitHandler}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <>
                  <form onSubmit={handleSubmit} className="form form__token">
                    <div className="form__body form__token-exchange">
                      <Field
                        id="amount"
                        component={CustomInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="amount"
                        value={values.amount}
                        autoComplete="off"
                        label="Amount"
                      />
                      {errors.amount && touched.amount && <Error>{errors.amount}</Error>}
                      {error && <Error>{error}</Error>}

                      <Button
                        height={'40px'}
                        id="token"
                        type="submit"
                        disabled={isSubmitting}
                        variant="solid"
                        bg="green.500"
                        colorScheme="black"
                        fontWeight={700}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                        display="flex"
                        align-items="center"
                        justify-content=" center"
                        background="#ffffff"
                        color="#FFFFFF"
                        margin-top="60px"
                        borderRadius="100px;"
                        className="form__button icon-rorschach"
                      >
                        Exchange
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ExchangeTokenForm;