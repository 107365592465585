import axios from 'axios';
import { BACKEND_REWARD_BASE_URL } from '../constants';

export const isUserRewardedInfo = async ({ id, address }) => {
  const response = await axios.get(
    `${BACKEND_REWARD_BASE_URL}/is-user-rewarded?contributor_address=${address}&project_id=${id}`
  );
  return response.data;
};

export const generateUserNft = async ({ data, setError }) => {
  try {
    const response = await axios.post(`${BACKEND_REWARD_BASE_URL}/generate-nft`, data);
    return response.data;
  } catch (e) {
    console.log('error', e);
  }
};
