import { zeroAddress } from "ethereumjs-util";

export const truncateAddress = (address) => {
  if (!address) return 'No Account';
  const match = address.match(/^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return '0x' + val.toString(16);
};

export const getTransactionExplorerUrl = (chainId) => {
  switch (chainId) {
    case 1:
      return 'https://etherscan.io/tx';
    default:
      return 'https://etherscan.io/tx';
  }
};

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  console.log('b64Data', b64Data);
  var byteCharacters = window.atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// const blobToImage = (blob) => {
//   return new Promise((resolve) => {
//     const url = URL.createObjectURL(blob);
//     let img = new Image();
//     img.onload = () => {
//       URL.revokeObjectURL(url);
//       resolve(img);
//     };
//     img.src = url;
//   });
// };

export const randomColor = () => {
  const red = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, '0');
  const green = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, '0');
  const blue = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, '0');
  return `#${red}${green}${blue}`;
};

export function object_reverse(a) {
  var b = [], c = [], d = {}

  for (var i in a) {
    b.unshift(i);
    c.unshift(a[i]);
  }

  for (var i in b) {
    d[b[i]] = c[i]
  }
  return d;
}

export const shortAddress = (address) => {
  if (address == null) return zeroAddress;
  const firstPart = address.substring(0, 6);
  const secondPart = address.slice(-4);
  return firstPart + '...' + secondPart;
};

export const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

export const getIpfsPath = (str, name) => {
  const array = str.data.split('}');
  const hashFolder = JSON.parse(array[1] + '}').Hash;
  return hashFolder + '/' + name;
};
