import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (base) => ({
    ...base,
    background: '#515156',
    color: '#C4C4C4',
    borderRadius: '5px',
    borderWidth: '0',
    // borderColor: 'none',
    // '&:hover': {
    //   borderColor: 'white'
    // }
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    background: '#29292E',
    color: '#C4C4C4',
    zIndex: 9999
  }),
  menuList: (base, state) => ({
    ...base,
    padding: 0,
    background: '#29292E'
  }),

  singleValue: (provided) => ({
    ...provided,
    height: '100%',
    paddingTop: '3px',
    color: '#C4C4C4',
  }),
  container: (provided) => ({
    ...provided,
    // width: 250
  }),
  indicatorsContainer: (prevStyle, state) => state.isMulti ? ({
    ...prevStyle,
    display: 'none'
  }) : null,
  dropdownIndicator: base => ({
    ...base,
    color: "#29292E", // Custom colour
    '&:hover': {
      color: '#29292E'
    }
  })
};

const MyCustomSelect = ({ onChange, options, defaultValue }) => (
  <Select
    styles={customStyles}
    options={options}
    onChange={onChange}
    isMulti={false}
    placeholder={"Select category..."}
    defaultValue={defaultValue}
    theme={(theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: '#28282D',
        primary: '#28282D'
      }
    })}
  />
);

export default MyCustomSelect;
