import './style.css';
import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Box, Image, Link, Flex, chakra, useToast } from '@chakra-ui/react';
import { getWallet } from '../../utils/requestApi/wallet';
import Avatar from '../../assets/images/eth-avatar.png';
// import { truncateAddress } from '../../utils/helper';
import ContributionForm from './contribution';
import WalletActions from './WalletActions';
import AccountAssets from './AccountAssets';
// import VerticalText from '../../components/VerticalText';
import CopyToClipboard from 'react-copy-to-clipboard';
import NumberFormat from 'react-number-format';
import CreateTokenForm from './createToken';
import ChangeTokenPriceForm from './changeTokenPrice';
import { daoFactoryAddress, MULTISIG_BASE_URL, zeroAddress } from '../../utils/constants';
import { ethers } from 'ethers';
import daoFactoryJson from '../../assets/abi/DAOFactory.json'
import daoTokenJson from '../../assets/abi/DAOToken.json'
import { getMultiSigAddresses } from '../../utils/requestApi';
import ExchangeTokenForm from './exchangeToken';
// import { StarIcon } from '@chakra-ui/icons';
import LikeIcon from './like';
// import TokenForm from './createToken';
import { Footer } from '../../components/Footer';
import { CustomButton } from '../../components/Button';

const projecTitles = ['DAO', 'Twitter', 'Forum'];

function WalletInfo() {
  const { id } = useParams();
  const toast = useToast();
  const { account, active } = useWeb3React();
  const [totalBalance, setTotalBalance] = useState(0);
  const [walletInfo, setWalletInfo] = useState('');
  const [tokenAddress, setTokenAddress] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  const getWalletInfo = async () => {
    const response = await getWallet({ id, userAddress: account });
    setWalletInfo(response.data);
  };

  const getTokenAddress = async () => {
    if (walletInfo.address) {

      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const signer = provider.getSigner();
      const daoABI = daoFactoryJson.abi;
      const daoContract = new ethers.Contract(daoFactoryAddress, daoABI, signer);
      let tokenAddress;
      try {
        tokenAddress = await daoContract.tokenPerDao(walletInfo.address);
      } catch (error) {
        console.log('error', error);
      }
      if (tokenAddress){
        setTokenAddress(tokenAddress);
        await getTokenPrice(tokenAddress, signer);
    }
    }
  }

  const getTokenPrice = async (tokenAddress, signer) => {

    const tokenABI = daoTokenJson.abi;
    const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
    const tokenPrice = await tokenContract.getPrice();
    const tokenTotalSupply = await tokenContract.totalSupply();

    console.log('tokenTotalSupply', tokenTotalSupply.toString());
    console.log('tokenPrice', ethers.utils.formatEther(tokenPrice));

    setTokenPrice(ethers.utils.formatEther(tokenPrice));
  }

  const getMultiSigAddressesInfo = async () => {
    if (account) {
      try {
        const response = await getMultiSigAddresses({ account });
        const isOwner = response.safes.includes(walletInfo.address);
        setIsOwner(isOwner);

      } catch (e) {
        console.log('error', e);
      }
    }
  }

  useEffect(() => {
    getMultiSigAddressesInfo();
  }, [account]);

  useEffect(() => {
    getTokenAddress();
    getMultiSigAddressesInfo()
  }, [walletInfo.address]);

  useEffect(() => {
    getWalletInfo();
  }, [active]);




  useEffect(() => {
    //установка картинок как background
    let nft_images = document.querySelectorAll('.nft__item-img');
    for (var img of nft_images) {
      img.parentElement.style.background = 'url(' + img.src + ') center no-repeat';
      img.parentElement.style.backgroundSize = 'cover';
      img.remove();
    }
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

{/* <div className="gradient-2 gradient" style={{bottom:'68%', top:'auto'}}></div>
      <div className="gradient-1 gradient" style={{bottom:'68%', top:'auto'}}></div>
      <div className="gradient-5 gradient" style={{bottom:'70%', top:'auto'}}></div> */}
    <div className="" style={{position:'absolute', left:0, top:0, height:'100%', width: '100%', overflow:'hidden'}}>

   
      <div className="gradient-2 gradient" style={{bottom:'70%', top:'auto'}}></div>
      <div className="gradient-1 gradient" style={{bottom:'70%', top:'auto'}}></div>
      <div className="gradient-5 gradient" style={{bottom:'72%', top:'auto'}}></div>

      <div className="gradient-2 gradient" style={{bottom:'30%', top:'auto'}}></div>
      <div className="gradient-1 gradient" style={{bottom:'30%', top:'auto'}}></div>
      <div className="gradient-5 gradient" style={{bottom:'32%', top:'auto'}}></div>
    </div>


      <div className="longevity">
        <div className="container">
          <div className="longevity__body">
            
            <div className="longevity__grid-head">
              <div className="longevity__grid__col">
                <div className="longevity__poster">
                  <Image
                    objectFit="cover"
                    boxSize="100%"
                    src={
                      walletInfo.avatar && walletInfo.avatar_type
                        ? `data:${walletInfo.avatar_type};base64,` + walletInfo.avatar
                        : Avatar
                    }
                    alt=""
                    h={"50vh"}
                    // w="330px"
                    // height="400px"
                  // className="longevity__poster-img"
                  />
                </div>
              </div>
              <div className="longevity__grid__col">

                
                <WalletActions walletInfo={walletInfo} getWalletInfo={getWalletInfo} />


                <div className="longevity__row" >
                  <h2 className="longevity__title"> {walletInfo.name}</h2>
                  
                  {active && walletInfo && <LikeIcon id={id} address={walletInfo.address} status={walletInfo.is_liked} />}
                  {/* <WalletActions walletInfo={walletInfo} getWalletInfo={getWalletInfo} /> */}
                </div>
                

                <div className="" style={{marginTop: -20}}>
                  <CopyToClipboard
                      text={walletInfo.address}
                      onCopy={() =>
                        toast({
                          position: 'top-right',
                          render: () => (
                            <Box color="white" p={3} bg="green.500" mt={10}>
                              Success!
                            </Box>
                          )
                        })
                      }
                    >
                      <chakra.span cursor={'pointer'} className="longevity__block-link">
                        {walletInfo.address && (walletInfo.address.slice(0,8) + '...' + walletInfo.address.slice(walletInfo.address.length - 6, walletInfo.address.length - 1))}
                        {/* {truncateAddress(walletInfo.address)} */}
                      </chakra.span>
                    </CopyToClipboard>
                </div>

                {/* <WalletActions walletInfo={walletInfo} getWalletInfo={getWalletInfo} /> */}

                <div className="longevity__text">
                  <p className="longevity__paragraph">{walletInfo.description}</p>
                </div>

                {/* <div className="longevity__row"> */}
                <div className="longevity__block ">
                {/* <p className="longevity__block-title">{networkName} EVM</p> */}
                  <h3 className="longevity__block-price"><NumberFormat value={parseFloat(totalBalance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
                  {/* <CopyToClipboard
                    text={walletInfo.address}
                    onCopy={() =>
                      toast({
                        position: 'top-right',
                        render: () => (
                          <Box color="white" p={3} bg="green.500" mt={10}>
                            Success!
                          </Box>
                        )
                      })
                    }
                  >
                    <chakra.span cursor={'pointer'} className="longevity__block-link">
                      {truncateAddress(walletInfo.address)}
                    </chakra.span>
                  </CopyToClipboard> */}
                </div>


              <div className="longevity__block longevity__block-left">
                {/* <p className="longevity__block-title _pc">DAO {walletInfo && walletInfo.projects[1] && 'Twitter'} Forum</p> */}
                <div className="longevity__btns">
                  {walletInfo && walletInfo.projects.length > 0
                    ? walletInfo.projects.map((item, index) => {
                      let icon;
                      switch (index) {
                        case 0: icon = 'icon-rocket'; break;
                        case 1: icon = 'icon-twitter'; break;
                        case 2: icon = 'icon-shell'; break;
                        default: icon = 'icon-hand';
                      }

                      if (item != '') {
                        return (
                          <>
                            {/* <Fragment key={index}>
                              <p className="longevity__block-title _mob">{projecTitles[index]}</p>

                              <a href={item} className={`longevity__btn ${icon}`} target={'_blank'}>
                                {projecTitles[index]}
                              </a>

                              <br />
                            </Fragment> */}
                            <CustomButton
                              props={{
                                label : projecTitles[index],
                                theme : "fifth",
                                type: "main",
                                isLink:true,
                                onClick : item
                                // onClick: () => handleChangeNetwork(1, handleSubmit)
                              }}
                            />
                          </>
                        );
                      }
                    })
                    : ''}
                </div>
              </div>

              {account && <ContributionForm toAddress={walletInfo.address} getWalletInfo={getWalletInfo} />}

            {/* </div> */}
              </div>
            </div>


{/*  */}
            <Flex flexFlow={"row"} gridGap={4} alignItems={"center"}>
              <>
                {tokenAddress == zeroAddress && isOwner && <>
                  <CreateTokenForm multisingAddress={walletInfo.address} getTokenAddress={getTokenAddress} />
                </>
                }
                {isOwner && <>
                  <Link href={`${MULTISIG_BASE_URL}/gor:${walletInfo.address}`} isExternal colorScheme="teal" color="white" >
                    Manage wallet
                  </Link>
                </>
                }
              </>

              {/* <Button background={"#ffffff"} colorScheme="teal"
              variant="solid" onClick={() => alert('Comming soon...')}>Subscription payment</Button> */}
              {tokenAddress && tokenAddress != zeroAddress && tokenPrice && <ExchangeTokenForm tokenAddress={tokenAddress} tokenPrice={tokenPrice} />}
              {tokenPrice && tokenAddress && isOwner && <ChangeTokenPriceForm currentPrice={tokenPrice} tokenAddress={tokenAddress} getTokenAddress={getTokenAddress} />}
            </Flex>

            {/* <Flex gridGap={1} pt={8} flexFlow={"column"}>
              {tokenAddress && tokenAddress != zeroAddress && <Text color={"white"}><chakra.span fontWeight={"bold"}>Token: </chakra.span> {tokenAddress}</Text>}
              {tokenPrice && <Text color={"white"}><chakra.span fontWeight={"bold"}>Price:</chakra.span>  {tokenPrice}</Text>}
              {totalSupply && <Text color={"white"}><chakra.span fontWeight={"bold"}>Total Supply:</chakra.span>  {totalSupply}</Text>}
            </Flex> */}

            {/* <div className="longevity__row">
              <div className="longevity__block">
                <TokenForm />
              </div>
            </div> */}

            
          </div>
        </div>
      </div>


      <AccountAssets setTotalBalance={setTotalBalance} address={walletInfo.address} id={id} walletStatus={walletInfo.status} />
      <Footer />
    </>
  );
}

export default WalletInfo;
