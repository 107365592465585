import { Box, Input } from '@chakra-ui/react';
import { getIn } from 'formik';
import PropTypes from 'prop-types';

const CustomInput = ({ field, form, label, description, onBlur, classNameLabel, classNameInput, ...props }) => {
  const handleBlur = (event) => {
    const {
      target: { value }
    } = event;

    if (value !== '' && !!onBlur) {
      onBlur(event);
    }
  };

  const { name, value, ...fieldProps } = field;

  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const isError = !!error && isTouched;

  return (
    <>
      <label htmlFor="" className={!classNameLabel ? 'form__label' : classNameLabel}>
        {label}

        <Input
          type="text"
          background={'#515156'}
          name={name}
          value={value === null ? '' : value}
          {...fieldProps}
          {...props}
          isInvalid={isError}
          onBlur={handleBlur}
          border="none"
          outline="none"
          marginBottom={!description ? "24px" : "4px"}
          className={!classNameInput ? 'form__input' : classNameInput}
        />
      </label>

      {/* <Input
        borderColor="gray.200"
        color="black"
        name={name}
        value={value === null ? '' : value}
        {...fieldProps}
        {...props}
        isInvalid={isError}
        onBlur={handleBlur}
      />*/}
      {description && <Box pb={4}>{description}</Box>}
    </>
  );
};

CustomInput.propTypes = {
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string.isRequired
};

CustomInput.defaultProps = {
  readOnly: false,
  autoFocus: false
};

export default CustomInput;
