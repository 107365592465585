import { useDisclosure, Button } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN, UPDATE_WALLET, WALLETS } from '../../../utils/routings';
import { approveWallet, declineWallet, deleteWallet } from '../../../utils/requestApi/wallet';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react';

const APPROVE_STATUS = "APPROVE";
const DECLINE_STATUS = "DECLINE";

const WalletActions = ({ walletInfo, getWalletInfo }) => {
  let navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const handleDelete = async () => {
    try {
      const response = await deleteWallet({ id: walletInfo.id });
      if (response.data.deleted) {
        navigate(WALLETS);
      }
    } catch (e) {
      if (e.response.status === 401) {
        localStorage.removeItem('jwtToken');
        navigate(LOGIN);
      }
      console.log('error', e);
    }
  };

  const handSetStatusWallet = async ({ status }) => {
    try {
      if (status === APPROVE_STATUS) {
        const response = await approveWallet({ id: walletInfo.id });

        console.log('response APPROVE_STATUS', response);
      } else {
        const response = await declineWallet({ id: walletInfo.id });
        console.log('response decline', response);
      }
      getWalletInfo();

    } catch (e) {
      if (e.response.status === 401) {
        localStorage.removeItem('jwtToken');
        navigate(LOGIN);
      }
    }
  }

  return (
    <>
      {localStorage.getItem('jwtToken') && (
        <div className="longevity__btns">
          <a href={UPDATE_WALLET + '/' + walletInfo.id} className="longevity__btn icon-hand active">
            Edit Wallet
          </a>

          <button className="longevity__btn icon-hand " onClick={onOpen}>
            Delete Wallet
          </button>

          {walletInfo.approved == null && (
            <>
              <button className="longevity__btn icon-hand " onClick={() => handSetStatusWallet({ status: APPROVE_STATUS })}>
                Approve Wallet
              </button>

              {walletInfo.approved != null &&
                <button className="longevity__btn icon-hand " onClick={() => handSetStatusWallet({ status: DECLINE_STATUS })}>
                  Decline Wallet
                </button>}
            </>
          )}
        </div>
      )}
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Wallet
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default WalletActions;
