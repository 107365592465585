import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';


import styles from './styles.module.css'


import axios from 'axios';
import CustomImage from './ImageComponent';

const Nfts = ({ address }) => {
  const [nfts, setNfts] = useState();
  const [size, setSize] = useState(0)
  const [colCount, setColCount] = useState(4)
  useEffect(() => {
    const options = { method: 'GET', headers: { Accept: 'application/json', 'X-API-Key': 'cCODPPtS944xqwAkR0g6Zgg3El8GTNX1IfolLEYmBDpnPGynwaV7xGhXux72Xprt' } };
    const getNfts = async () => {
      try {
        const response = await axios(`https://deep-index.moralis.io/api/v2/${address}/nft?chain=eth&format=decimal`, options)
        setNfts(response.data);
      } catch (e) {
        console.log('error', e);
      }
    };

    if (address) getNfts();
  }, [address]);

  async function imageExists(image_url){
    let status = ''
    const img = await fetch(image_url)
      // .then(response => response.status === 404 ? status = response.status : status = true)
      // .catch(err => status = 404)

    // console.log(img.ok)


    if(img.status !== 200){
      return false;
    }else{
      return true;
    }

  }

  const getImageSrc = (metadata) => {
    const src = metadata.image_full_s3 ? metadata.image_full_s3 : metadata.image;
    
    if (src && src.indexOf('ipfs://ips') === 0) {
      return imageExists(src.replace('ipfs://ips', 'https://ipfs.io/ipfs/')) ? src.replace('ipfs://ips', 'https://ipfs.io/ipfs/') : false;
    }
    else if (src && src.indexOf('ipfs://') === 0) {
      return imageExists(src.replace('ipfs://', 'https://ipfs.io/ipfs/')) ? src.replace('ipfs://', 'https://ipfs.io/ipfs/') : false;
    }
    else if (metadata.image_url) {
      return metadata.image_url;
    } else {

    }    
    return imageExists(src) ? src : false
  }

  async function checkImg(event, metadata){
    const src = await getImageSrc(metadata)
    const flag = await imageExists(src)

    console.log(event.currentTarget)
    event.src = flag ? src : 'https://imgholder.ru/300x300/8493a8/adb9ca&text=NFT+&font=kelson'
  }


  function updateSize() {
    setSize(window.innerWidth);
}
  useEffect(()=>{
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  },[])

  useEffect(()=>{
    if(size <= 400 && setColCount !== 1){
      setColCount(1)
    }
    else if(size <= 600 && size > 400 && setColCount !== 2){
      setColCount(2)
    }else if(size <= 900 && size > 600 && setColCount !== 3){
      setColCount(3)
    }else if(size > 900 && setColCount !== 4){
      setColCount(4)
    }
  },[size])

  return (
    <>
      <div className="nft" data-category="nft" hidden>
        {nfts && nfts.result && <>
          <h3 className="nft__title">{nfts.result.length} NFTs</h3>
    
          <Box
            className={styles.monsary}
            padding={4}
            w="100%"
            // mx="auto"
            sx={{ columnCount: colCount, gap: "15px" }}
          >
            {nfts.result.map((item, index) => {
              
              const metadata = JSON.parse(item.metadata);
              return (<Box
                  display={'flex'}
                  flexDirection="column"
                  padding={'10px'}
                >
                  
                  <CustomImage
                    props={{
                      metadata,
                      item
                    }}
                  />
                
                <Box
                    // mb={5}
                    color={'#FFF'}
                    textAlign="center"
                  >
                    {item.name}
                  </Box>
                  
                </Box>)
              })}
            
          </Box>
          {/* <div className={styles.row}>
            <div className={styles.column} >
              
              <img src="https://images.unsplash.com/photo-1506630525117-569864448381?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=2b312fb1e449aa4986c29c83752597e6&auto=format&fit=crop&w=934&q=80" />
            
              
              <img src="https://images.unsplash.com/photo-1496153615838-861aed350146?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=95f63835f92b3726a9d675412d421b2a&auto=format&fit=crop&w=2590&q=80" />
              <img src="https://images.unsplash.com/photo-1503249023995-51b0f3778ccf?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=9df0dd21ec52afa496c963a48da2d287&auto=format&fit=crop&w=911&q=80" />

            </div>

            <div className={styles.column}>
              <img src="https://images.unsplash.com/photo-1482192596544-9eb780fc7f66?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=bbe0bd1ecfaaa91394e0c9effb8b0415&auto=format&fit=crop&w=2550&q=80" />
              <img src="https://images.unsplash.com/photo-1516810714657-e654b97f1d80?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4b0b6d8a68ebad0eada0e4da17737094&auto=format&fit=crop&w=934&q=80" />
              <img src="https://images.unsplash.com/photo-1496871455396-14e56815f1f4?ixlib=rb-0.3.5&s=2460fc20c6ed6192b7b5e1b6a2d375bf&auto=format&fit=crop&w=917&q=80" class="web" />
            </div>

            <div className={styles.column}>
              <img src="https://images.unsplash.com/uploads/141150092992295b16435/00a01fcc?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=d5f3a6866acc7cfdcccc0d20915f287a&auto=format&fit=crop&w=2389&q=80" />
              <img src="https://images.unsplash.com/photo-1507208773393-40d9fc670acf?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b6768885e6bbb2cef343010ce679b6d0&auto=format&fit=crop&w=934&q=80" />
              <img src="https://images.unsplash.com/photo-1477519242566-6ae87c31d212?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=583c669646c51ffb38d9955a614cb9a9&auto=format&fit=crop&w=2550&q=80" />
              <img src="https://images.unsplash.com/photo-1516382022989-cd771ab91fb1?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b1642cc1bc43322d27084ea320d179fc&auto=format&fit=crop&w=1867&q=80" />

            </div>

            <div className={styles.column}>
              <img src="https://images.unsplash.com/photo-1508392196713-1391ddb22a2a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=a2d8de5501579c2794aa2f256c18a3e7&auto=format&fit=crop&w=2550&q=80" />
              <img src="https://images.unsplash.com/photo-1502988632461-f68f5b4f415a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=62f374f402c312b31598298247451da1&auto=format&fit=crop&w=934&q=80" />
              <img src="https://images.unsplash.com/photo-1516306305827-b74eb3993a0a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=e246dfa4c3962c28793b48ec172227ff&auto=format&fit=crop&w=1866&q=80" />
              <img src="https://images.unsplash.com/photo-1494522358652-f30e61a60313?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=c39e2e693011b17dfc47d070250419fc&auto=format&fit=crop&w=2550&q=80" />
            </div>
            
          </div> */}
          {/* <div className="nft__list">
            {nfts.result.map((item, index) => {
              const metadata = JSON.parse(item.metadata);
              return (
                <div className="nft__item" key={`item-${index}`}>
                  <Image
                    // src={
                    //   metadata
                    //     ? getImageSrc(metadata) || 'https://imgholder.ru/300x300/8493a8/adb9ca&text=NFT+&font=kelson'
                    //     : 'https://imgholder.ru/300x300/8493a8/adb9ca&text=NFT+&font=kelson'
                    // }
                    // alt={`Picture of ${item.name}`}
                    onLoad={
                      async (e) => {
                        await checkImg(e, metadata)
                      }
                    }
                    // roundedTop="lg"
                    borderRadius="16px"
                    // roundedBottom="lg"
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    // height="220px"
                    className="nft__item-img"
                  />
                  {item.name}
                </div>
              );
            })}
          </div> */}
        </>
        }

        {!nfts && <div className='no-data_container'>
          <svg width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="71" cy="71" r="71" fill="rgba(53, 156, 251, 0)" stroke='#fff' strokeWidth={2} fillOpacity="1"/>
            <path d="M40 42.5H57L63 47.5H98.5C102.918 47.5 106.5 51.0817 106.5 55.5V56.5V65.5H56L43 100H40C36.6863 100 34 97.3137 34 94V48.5C34 45.1863 36.6863 42.5 40 42.5Z" fillOpacity="0" fill="white"/>
            <path d="M106.632 65.2V53.4588C106.632 50.1451 103.946 47.4588 100.632 47.4588H62.6437L59.5311 44.3446C58.0307 42.8434 55.9953 42 53.8728 42H40C36.6863 42 34 44.6863 34 48V93C34 96.866 37.134 100 41 100H42.8659M106.632 65.2H121.6C122.286 65.2 122.768 65.8753 122.546 66.5244L111.992 97.2976C111.438 98.9142 109.917 100 108.208 100H42.8659M106.632 65.2H58.6026C56.9319 65.2 55.4371 66.2385 54.8541 67.8042L42.8659 100" stroke="#ffffff" strokeWidth="2"/>
          </svg>
          No data
        </div>}
      </div>
    </>
  );
};

export default Nfts;
