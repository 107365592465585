import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';
import styles from './style.module.css'

const Loading = () => {

  return (
    <div className={styles.loader}>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
    </div>
  )

  return (
    <Box display="flex" justifyContent="center" pt={2} pb={4}>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#ffffff" size="xl" />
    </Box>
  );
};

export default Loading;
