import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const Error = ({ children }) => {
  return <Box color="red.400">{children}</Box>;
};

export default Error;

Error.propTypes = {
  children: PropTypes.string.isRequired,
};
