


export const ForumPage = () => {

    return (
        <>
            10 000$
        </>
    )
}