/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { toHex } from '../../utils/helper';
// import { networkParams } from '../../utils/networks';
import { connectors } from '../../utils/connectors';
import SelectWalletModal from '../Modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HOME, PROFILE, LOGIN, ADMIN_WALLETS, CREATE_WALLET, WALLETS } from '../../utils/routings';
import './style.css';
import { networks } from '../../utils/constants';
import { Tooltip } from '@chakra-ui/react'
import { HeaderDropdown } from '../Dropdown';
import LogoIMG from '../../assets/img/logo_pAI.svg'
import KeylockIMG from '../../assets/img/keylock.svg'
// import WrongNetworkModal from '../WrongNetworkModal';

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { library, chainId, activate, deactivate, active, account } = useWeb3React();
  const [, setError] = useState('');
  const [ network, setNetwork] = useState(undefined);
  const [networkName, setNetworkName] = useState(undefined);
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (chainId) {
      let _network = networks.find((x) => x.id === chainId);
      if (_network) {
        setNetworkName(_network.name);
      }
    }
  }, [chainId]);

  const handleNetwork = ({ id, name }) => {
    changeNetwork(id);
  };

  const changeNetwork = (id) => {
    setNetwork(Number(id));
    switchNetwork(Number(id));
  };

  const switchNetwork = async (id) => {
    try {
      if(!active){
        await onOpen()
      }
      
      await library.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHex(id) }]
      });
      setNetworkName(networks.find((x) => x.id === id).name);
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: 'wallet_addEthereumChain',
            // params: [networkParams[toHex(network)]],
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const refreshState = () => {
    window.localStorage.setItem('provider', undefined);
    setNetwork('');
  };

  const disconnect = () => {
    refreshState();
    deactivate();
  };

  useEffect(() => {
    const provider = window.localStorage.getItem('provider');
    if (provider) activate(connectors[provider]);
  }, [activate]);


  const burger_btn = useRef(null)
  const left_nav = useRef(null)
  const header_title = useRef(null)

  const handleBurgerClick = (e) => {
    e.currentTarget.classList.toggle('active');
    left_nav.current.classList.toggle('active');
  }

  useEffect(() => {
    window.addEventListener('resize', () =>{
      left_nav.current.style.width = window.innerWidth + 'px';
      if (window.innerWidth >= 1250) {
        left_nav.current.style.width = 'auto';
      }
    })
  }, []);

  const closeMenu = () =>{
    burger_btn.current.classList.toggle('active');
    left_nav.current.classList.toggle('active');
  }

  return (
    <>
      {/* {<WrongNetworkModal isOpen={active && chainId !== 1} changeNetwork={changeNetwork} />} */}

      <header className="header">
        <div className="container">
          <div className="header__body">
            <div className="header__btns" ref={left_nav}>
            {<a className="header__btn" onClick={() => {
                navigate(`${WALLETS}`)
                closeMenu()
                }} >
                <span className={location.pathname === WALLETS ? 'active' : ''}>Explore</span>
              </a>}

              

              {
              // active
              active && <a className="header__btn" onClick={() => {
                navigate(`${PROFILE}/${account}`)
                closeMenu()
                }}>
                

                <span className={location.pathname === `${PROFILE}/${account}` ? 'active' : ''}>My profile
                </span>
              </a>}

              {!active && <Tooltip  label="Please connect your wallet" aria-label='A tooltip'>
                <a className="header__btn not-active">
                  <span className='not-active'>
                    My profile
                    <span className='keylock'>
                      <img src={KeylockIMG} style={{height: 12}} alt="keylock" />
                    </span>
                  </span>
                </a>
              </Tooltip>}

              {
              // active 
              active && <a className="header__btn" onClick={() => {
                  navigate(`${CREATE_WALLET}`) 
                  closeMenu()
                }}
              >
                <span className={location.pathname === CREATE_WALLET ? 'active' : ''}>Add DAO</span>
              </a>}

              {!active && <Tooltip  label="Please connect your wallet" aria-label='A tooltip'>
                <a className="header__btn not-active">
                  <span className='not-active'>
                    Add DAO
                    <span className='keylock'>
                      <img src={KeylockIMG} style={{height: 12}} alt="keylock" />
                    </span>
                  </span>
                </a>
              </Tooltip>}
              

              {<a className="header__btn" href='https://biolinks.privateai.com' target={"_blank"} rel="noreferrer">
                <span>Biolinks</span>
              </a>}

              {<a className="header__btn disabled">
                  <span className='main_link'>Forum</span>
                  <span className='hover'>Coming soon</span>
              </a>}


              {/* <HeaderDropdown 
                props={{
                  currentLabel : active && networkName || 'Switch Network',
                  className: 'mobile_custom',
                  list: networks.map(_network => {
                    return {
                      label: _network.name,
                      onclick:() => handleNetwork(_network)
                    }
                  })
                }}
              /> */}


              {!active ? (
                <button className="header__btn header__btn-not-fill exo icon-triangle mobile_custom" style={{
                  fontFamily: 'Satoshi',
                  fontSize: '16px',
                  marginRight: 0
                }} onClick={onOpen}>
                  Connect Wallet
                </button>
              ) : (
                <button className="header__btn header__btn-not-fill exo icon-triangle mobile_custom" style={{
                  fontFamily: 'Satoshi',
                  fontSize: '16px',
                  marginRight: 0
                }} onClick={disconnect}>
                  Disconnect
                </button>
              )}

              {localStorage.getItem('jwtToken') && <>
                <a className="header__btn mobile_custom" onClick={() => {
                  navigate(ADMIN_WALLETS)
                  closeMenu()
                }}>
                  <span className={location.pathname === ADMIN_WALLETS ? 'active' : ''}>Admin page</span>
                </a>
              </>}

              {localStorage.getItem('jwtToken') && <>
                <a className="header__btn mobile_custom" onClick={() => { localStorage.removeItem('jwtToken'); navigate(HOME); }}>
                  <span>Log out</span>
                </a>
              </>}
              
            </div>
            <Link to={HOME} className="header__logo" >
              <h3 className="header__title" ref={header_title} style={{ display: 'inline-flex', alignItems: 'center', textTransform: 'uppercase' }} >
                <img src={LogoIMG} style={{height:60}} />
              </h3>
            </Link>
            <div className="header__burger _mob" ref={burger_btn} onClick={handleBurgerClick}>
              <span></span>
            </div>

            <div className="header__btns">
              {/* <HeaderDropdown 
                props={{
                  currentLabel : active && networkName || 'Switch Network',
                  list: networks.map(_network => {
                    return {
                      label: _network.name,
                      onclick:() => handleNetwork(_network)
                    }
                  })
                }}
              /> */}
           

              {!active ? (
                <button className="header__btn header__btn-not-fill  " style={{
                  fontFamily: 'Satoshi',
                  fontSize: '16px',
                  marginRight: 0
                }} onClick={onOpen}>
                  {/* <span> */}
                  Connect Wallet
                  {/* </span> */}
                </button>
              ) : (
                <button className="header__btn header__btn-not-fill  " style={{
                  fontFamily: 'Satoshi',
                  fontSize: '16px',
                  marginRight: 0
                }} onClick={disconnect}>
                  {/* <span> */}
                  Disconnect

                  {/* </span> */}
                </button>
              )}

{localStorage.getItem('jwtToken') && <>
                <a className="header__btn"  style={{marginLeft:16}}  onClick={() => {
                  navigate(ADMIN_WALLETS)
                  closeMenu()
                }}>
                  <span className={location.pathname === ADMIN_WALLETS ? 'active' : ''}>Admin page</span>
                </a>
              </>}

              {localStorage.getItem('jwtToken') && <>
                <a className="header__btn"onClick={() => { localStorage.removeItem('jwtToken'); navigate(HOME); }}>
                  <span>Log out</span>
                </a>
              </>}
            </div>
          </div>
        </div>
      </header >
      <SelectWalletModal isOpen={isOpen} closeModal={onClose} />

      {/* <Box>
        <Flex
          bg={'white'}
          color={'gray.600'}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={'gray.200'}
          align={'center'}
        >
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Link to={HOME}>
              <Text
                textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                fontFamily={'heading'}
                color={'gray.800'}
              >
                Wallet Explorer
              </Text>
            </Link>
          </Flex>

          <Stack
            flex={{ base: 1 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={4}
            alignItems="center"
          >
            {!localStorage.getItem('jwtToken') ? (
              <Link to={LOGIN}>
                <Button
                  as={'a'}
                  fontSize={'sm'}
                  fontWeight={400}
                  variant={'link'}
                  href={'#'}
                >
                  Log In
                </Button>
              </Link>
            ) : (
              <Button
                as={'a'}
                fontSize={'sm'}
                fontWeight={400}
                variant={'link'}
                onClick={() => {
                  localStorage.removeItem('jwtToken');
                  navigate(HOME);
                }}
              >
                Log out{' '}
              </Button>
            )}
            {!active ? (
              <Button onClick={onOpen}>Connect Wallet</Button>
            ) : (
              <>
                <VStack>
                  <Select onChange={handleNetwork} value={chainId}>
                    <option value="1">Ethereum</option>
                    <option value="1280">MoonRabbit</option>
                  </Select>
                </VStack>

                <Button onClick={disconnect}>Disconnect</Button>
              </>
            )}
          </Stack>
        </Flex>
      </Box>
      <SelectWalletModal isOpen={isOpen} closeModal={onClose} /> */}
    </>
  );
};

export default Header;
