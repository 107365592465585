/* global BigInt */

import { ethers, utils } from 'ethers';
import { REWARDS_CONTRACT_ADDRESS } from '../constants';
import rewardsJson from '../../assets/abi/Rewards.json';
import daoTokenJson from '../../assets/abi/DAOToken.json';

export const mintNftContract = async ({ address, tokenURI, projectId, setError, cb }) => {
  const web3 = window.ethereum;

  if (web3) {
    const provider = new ethers.providers.Web3Provider(web3);
    const signer = provider.getSigner();

    const rewardContract = new ethers.Contract(REWARDS_CONTRACT_ADDRESS, rewardsJson.abi, signer);
    await rewardContract.mint(tokenURI, projectId);
    cb();
  } else {
    window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }
};

export const mintTokens = async ({ address, amount, onError, cb, tokenPrice }) => {
  const web3 = window.ethereum;

  if (web3) {
    const provider = new ethers.providers.Web3Provider(web3);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(address, daoTokenJson.abi, signer);

    const floatAmount = parseFloat(amount);
    const tokenPriceWei = ethers.utils.parseEther(tokenPrice.toString());
    const weiCost = tokenPriceWei * floatAmount

    const options = {
      value: weiCost.toString()
    };

    const weiAmount = ethers.utils.parseEther(floatAmount.toString()).toString();

    console.log('*******');
    console.log('options.value', options.value.toString());
    console.log('weiAmount', weiAmount);
    console.log('*******');
    const result = await tokenContract.mint(weiAmount, options);
    await result.wait();
    cb();
  } else {
    onError();
    window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }
};

