import { Box } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import MyCustomSelect from "../../ui-kit/CustomField/CustomSelect";
import { getWalletCategories } from "../../utils/requestApi/wallet";

function SelectCategory({ setFieldValue, isFilter }) {
  const [categories, setCategories] = useState();

  const getCategories = async () => {
    const response = await getWalletCategories();
    const selectOptions = [];

    response.data.map((item) => {
      selectOptions.push({ value: item.id, label: item.name });
    });

    setCategories(selectOptions);
  }

  useEffect(() => {
    if (isFilter)
      setFieldValue('category', categories ? categories[0]?.value : null);
  }, [categories]);


  useEffect(() => {
    getCategories();
  }, []);

  return <>
    {categories &&
      <Box pb={4}>
        <label htmlFor="" className="form__label">
          Category
        </label>
        <MyCustomSelect onChange={(e) => { setFieldValue('category', e.value); console.log(e.value) }} options={categories} defaultValue={categories[0]} />
      </Box>
    }
  </>;
}

export default SelectCategory;