export const LOGIN = '/auth/login';
export const HOME = '/';
export const CREATE_WALLET = '/wallet/create';
export const UPDATE_WALLET = '/wallet/update';
export const WALLETS = '/wallets';
export const FORUM = '/forum';
export const WALLET_INFO = '/wallet/:id';
export const WALLET = '/wallet';
export const PROFILE = '/profile';

export const ADMIN_WALLETS = '/admin/wallets';
