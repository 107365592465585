import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
};

root.render(
  <ChakraProvider>
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        {/* <MoralisProvider appId="7ItJjdoU82PIiRyNvT4uYBT6IEQEcFd4Prv8vgK1" serverUrl="https://myoqya8f1fxi.usemoralis.com:2053/server"> */}
          <App />
        {/* </MoralisProvider> */}
      </BrowserRouter>
    </Web3ReactProvider>
  </ChakraProvider>
);

reportWebVitals();
