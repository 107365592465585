import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { getTokenBalance } from '../../../utils/requestApi/wallet';
import { Link } from 'react-router-dom';
import { WALLET } from '../../../utils/routings';
import {
    chakra,
    Image,
  } from '@chakra-ui/react'
import Avatar from '../../../assets/images/eth-avatar.png';
import TwitterImg from '../../../assets/img/twitter.png';
import ProjectImg from '../../../assets/img/project.png';
import ForumImg from '../../../assets/img/forum.png';
import LikeIcon from '../../../pages/WalletInfo/like';
import NumberFormat from 'react-number-format';

export function CategoryItem({ data, showLikeIcon = true }) {
    const { account, active } = useWeb3React();
    const [totalBalance, setTotalBalance] = useState(0);
  
    const getAccountTokens = async () => {
      const response = await getTokenBalance({ id: data.id, page: 1 });
      const { pie, total_count, total } = response.data;
      // setTokensInfo({ tokens: Object.entries(pie), totalTokens: parseInt(total_count) });
      setTotalBalance(total);
    };
  
    useEffect(()=>{
      getAccountTokens()
    },[data])
    // console.log(data);
  
    return (
      // <div className="wallets__item-wrap">
  
      
       <Link to={`${WALLET}/${data.id}`} className="wallets__item">
           <div className="wallets__item-inner">
            <div className="wallets_item_left-col">
              <div className="wallets_item_left-col_featured-img">
              
                <Image src={data.avatar && data.avatar_type
                  ? `data:${data.avatar_type};base64,` + data.avatar
                  : Avatar} alt="" className="wallets__image wallets__item-block" 
                  w={"100%"} 
                  // w={"73px"} height={"73px"} minW={73} 
                  borderRadius={10}
                 />
                   
              </div>
              <div className="wallets__item-social">
                {data?.projects[0] && <a href={data.projects[0]}><Image src={ProjectImg} w={"18px"} h={"18px"} /></a>}
                {data?.projects[1] && <a href={data.projects[1]}><Image src={TwitterImg} w={"18px"} h={"18px"} /></a>}
                {data?.projects[2] && <a href={data.projects[2]}><Image src={ForumImg} w={"18px"} h={"18px"} /></a>}
              </div>
            </div>
            <div className="wallets__item-block">
              <p className="wallets__item-title"><chakra.span>{data.name.length > 20 ? data.name.slice(0, 15) + '...' : data.name} </chakra.span>  {active && data.is_liked && <LikeIcon id={data.id} address={account} status={data.is_liked} size="4" />}</p>
              <h3 className="wallets__item-price"><NumberFormat value={parseFloat(totalBalance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
  
              {/* <div className="wallets__item-social">
                {data.projects[0] && <a href={data.projects[0]}><Image src={ProjectImg} w={"20px"} h={"20px"} /></a>}
                {data.projects[1] && <a href={data.projects[1]}><Image src={TwitterImg} w={"20px"} h={"20px"} /></a>}
                {data.projects[2] && <a href={data.projects[2]}><Image src={ForumImg} w={"20px"} h={"20px"} /></a>}
              </div> */}
              <p className="wallets__item-description">
                {data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description}
                </p>
              
            </div >
          </div>
  
          
  
        </Link>
        
    );
}