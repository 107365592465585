import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLeaderboard } from "../../../../utils/requestApi/wallet";
import { Chart } from 'chart.js';


function TopContributerTokenPie() {
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {

    if (data && data.length > 0) {
      var moveToFunction = CanvasRenderingContext2D.prototype.moveTo;
      CanvasRenderingContext2D.prototype.moveToLocation = {};
      // Переопределение метода moveTo
      CanvasRenderingContext2D.prototype.moveTo = function (x, y) {
        this.moveToLocation.x = x;
        this.moveToLocation.y = y;
        moveToFunction.apply(this, [x, y]);
      };
      CanvasRenderingContext2D.prototype.dashedLineTo = function (x, y, dashedLength) {
        dashedLength = dashedLength === undefined ? 5 : dashedLength;
        var startX = this.moveToLocation.x;
        var startY = this.moveToLocation.y;
        var deltaX = x - startX;
        var deltaY = y - startY;
        var numberDash = Math.floor(Math.sqrt(deltaX * deltaX + deltaY * deltaY) / dashedLength);
        for (var i = 0; i < numberDash; i++) {
          this[i % 2 === 0 ? 'moveTo' : 'lineTo'](startX + (deltaX / numberDash) * i, startY + (deltaY / numberDash) * i); // эквивалентно this.moveTo (x, y) или this.LineTo (x, y)
        }
        this.moveTo(x, y); // Когда пунктирная линия рисуется непрерывно, начальная точка начинается с текущей точки
      };

      CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        this.beginPath();
        this.moveTo(x + r, y);
        this.arcTo(x + w, y, x + w, y + h, r);
        this.arcTo(x + w, y + h, x, y + h, r);
        this.arcTo(x, y + h, x, y, r);
        this.arcTo(x, y, x + w, y, r);
        this.closePath();
        return this;
      }

      Number.prototype.customFixed = function (fixed) {
        var number = this;
        number = String(number);
        var split = number.split('.');
        if (split.length > 1) {
          var left = split[0];
          var right = split[1].substr(0, (!fixed ? 4 : fixed));
          return Number(left + (fixed !== 0 ? '.' + right : ''));
        } else {
          return Number(number);
        }
      }
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //initialization

      const canvas = document.getElementById('analyticsChartDonut')
      const ctx = canvas.getContext('2d');

      //Custom tooltip
      const customTooltip = {
        id: 'customTooltip',
        afterDraw(chart) {
          const { ctx, data, chartArea: { top, bottom, left, right, width, height }, scales: { x, y }, tooltip: { _active } } = chart
          // x, y, w, h, r
          if (_active[0]) {
            let x_rectangle = _active[0].element.x - 215 / 2
            let y_rectangle = _active[0].element
            let x_left_text = _active[0].element.x - 215 / 2 + 36
            let x_right_text = _active[0].element.x - 215 / 2 + 86.5
            console.log(y_rectangle)

            ctx.beginPath()
            ctx.fillStyle = 'rgba(77, 78, 88, 0.4)'
            ctx.roundRect(x_rectangle, top + 36, 225, 48, 8).fill()

            ctx.font = '700 14px Satoshi'
            ctx.fillStyle = '#ffffff'
            let label = Math.round(data.labels[[_active[0].index]] * 100) / 100
            ctx.fillText(`${label} USD`, x_right_text, top + 44 + 30)
          }
        }
      }

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //Config
      const analyticsChartDonut = new Chart(ctx, {
        type: 'doughnut',
        weight: 30,

        data: {
          labels: data.map(el => {
            return el.sum
          }),
          datasets: [{
            borderWidth: 0,
            data: data.map(el => {
              return el.sum
            }),
            backgroundColor: [
              '#878787',
              '#A9A9A9',
              '#E6E6E6',
              
              
              '#61C6A9',
              'yellow',
              'pink',
            ],

          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: 110,

          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return (Math.round(analyticsChartDonut.data['datasets'][0]['data'][tooltipItem.dataIndex] * 100) / 100) + '  USD';
                },

              },
              backgroundColor: 'rgba(77, 78, 88, 0.5)',

              displayColors: false,
              bodyColor: '#FFF',
              bodyFont: {
                family: 'Satoshi',
                size: 18,
                weight: 700,
                height: 16,
              },
              padding: {
                top: 20,
                bottom: 20,
                left: 60,
                right: 60
              }
            }
          },
        },
        // plugins: [customTooltip],
      });

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //Scripts

      let contributorList = document.querySelector('.contributors__list')
      let seeAllLink = document.querySelector('.contributors__link')

      function fillContributorList(data) {
        contributorList.innerHTML = ''
        data.forEach(el => {
          let label = el.address.split('').map((el, index, a) => {
            if (index < 8 || index > a.length - 8) {
              return el
            } else if (index > 8 && index < 12) {
              return '.'
            }
          }).join('')
          let sum = Math.round(el.sum * 1000) / 1000
          let contributorItem = `
            <div class="contributors__item">
                <p class="contributors__label">${label} </p>
                <p class="contributors__sum">$${sum}</p>
            </div>
          `
          contributorList.innerHTML += contributorItem
        })
      }

      let contributorItems = document.querySelectorAll('.contributors__item')
      contributorItems.forEach(el => {
        el.addEventListener('click', function (e) {
          copyToClipboard(this.dataset['address'])
        })
      })

      fillContributorList(data)

      //Функция копирования в буфер
      function copyToClipboard(text) {
        navigator.clipboard.writeText(text)
        // .then(() => {
        //     console.log('Text copied to clipboard');
        // })
        // .catch(err => {
        //     console.error('Error in copying text: ', err);
        // });
      }

      seeAllLink.addEventListener('click', function (e) {
        e.preventDefault()
        let navButton = document.querySelector('.navigation__item[data-target=contributions]')
        navButton.click()
        let contributions = document.querySelector('.contributions')
        contributions.scrollIntoView({ block: "start", inline: "start" }, { behavior: "smooth" })
      })


      // return () => {
      //   analyticsChartDonut.destroy();
      // }
    }
  }, [data]);

  useEffect(() => {
    const getTokens = async () => {
      const response = await getLeaderboard({ id });
      console.log(response.data);
      setData(response.data);
    }

    getTokens();
  }, []);

  return <>
    {data && data.length > 0 && <>
      <h2 className="analytics__title">
        Top DAO Contributors
      </h2>
      <h3 className="analytics__subtitle">
        USD
      </h3>
      <div className="contributors">
        <div className="analytics__container">
          <canvas id="analyticsChartDonut" height="384"></canvas>
        </div>
        <div className="contributors__list">
        </div>
        <div className="contributors__link-wrap">
        <a href="#" className="contributors__link">See All Contributions</a>
        </div>
        
      </div>
    </>}

  </>;
}

export default TopContributerTokenPie;