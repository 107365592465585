import { Box, Button } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import CustomInput from '../../ui-kit/Input';
import { useNavigate } from 'react-router-dom';
import './style.css';
import useAuth from '../../utils/hooks';
import { HOME } from '../../utils/routings';
import { LoginSchema } from './validation';
import Error from '../../ui-kit/Error';
import GradientLine from '../../components/GradientLine';
import { Footer } from '../../components/Footer';

function SignIn2() {
  let navigate = useNavigate();

  const { loginUser, error } = useAuth();
  const [formData, setFormData] = useState('');

  const initialValues = {
    username: '',
    password: ''
  };
  const onError = () => {
    formData.actions.setSubmitting(false);
  };

  const handleLogin = async () => {
    await loginUser({ data: formData.values, onError });
    console.log('error', error);
    // updateUserData();
  };

  useEffect(() => {
    if (formData !== '') handleLogin();
  }, [formData]);

  const onSubmitHandler = useCallback((values, actions) => {
    setFormData({ values, actions });
  }, []);

  if (localStorage.getItem('jwtToken')) {
    navigate(HOME, { replace: true });
  }

  return (
    <>

      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        validateOnChange={false}
        onSubmit={onSubmitHandler}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <>
            <form onSubmit={handleSubmit} className="form">
              <div className="form__body">
                <h2 className="form__title">Sign In</h2>

                <Field
                  id="username"
                  component={CustomInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="username"
                  value={values.username}
                  autoComplete="off"
                  label="Username"
                // placeholder="Fill your username"
                />
                {errors.username && touched.username && <Error>{errors.username}</Error>}

                <Field
                  id="password"
                  component={CustomInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  name="password"
                  value={values.password}
                  autoComplete="off"
                  label="Password"
                // placeholder="Fill your username"
                />
                {errors.password && touched.password && <Error>{errors.password}</Error>}

                {error && <Box color="red.400">{error}</Box>}
                {/* <button type="submit" className="form__button icon-rorschach">
                  Login
                </button> */}
                <Button
                  height={'40px'}
                  id="login"
                  type="submit"
                  disabled={isSubmitting}
                  variant="solid"
                  bg="green.500"
                  _hover={{ bg: "#515156" }}
                  // size="lg"
                  // colorScheme="black"
                  fontWeight={700}
                  isLoading={isSubmitting}
                  loadingText="Submitting"
                  display="flex"
                  align-items="center"
                  justify-content=" center"
                  background="#ffffff"
                  color="#000"
                  margin-top="60px"
                  borderRadius="100px;"
                  className="form__button icon-rorschach"
                >
                  Login
                </Button>
              </div>
            </form>
          </>
        )}
      </Formik>
      {/* <form className="form">
        <div className="form__body">
          <h2 className="form__title">Sign In</h2>
          <label for="" className="form__label">
            Username
            <input
              type="text"
              className="form__input"
              placeholder="Fill your username"
            />
          </label>
          <label for="" className="form__label">
            Password
            <input
              type="text"
              className="form__input"
              placeholder="Fill your password"
            />
          </label>
          <button type="submit" className="form__button icon-rorschach">
            Login
          </button>
        </div>
      </form> */}

      {/* <p className="bottom_to_top vertical__text">DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;</p> */}
      {/* <p className="top_to_bottom vertical__text">DESCI&nbsp;DEVELOPER&nbsp;GUILD&nbsp;</p> */}
      <GradientLine />
      <Footer />
    </>
  );
}

export default SignIn2;
