import * as yup from 'yup';

export const ContributionSchema = yup.object().shape({
  value: yup.number().required('Required').moreThan(0)
});

export const TokenSchema = yup.object().shape({
  multisingAddress: yup.string().required('Required'),
  name: yup.string().required('Required'),
  symbol: yup.string().required('Required'),
  startPrice: yup.number().required('Required').moreThan(0),
});

export const TokenPriceSchema = yup.object().shape({
  tokenPrice: yup.number().required('Required').moreThan(0),
});

export const ExchangeTokenSchema = yup.object().shape({
  amount: yup.number().required('Required').moreThan(0),
});


