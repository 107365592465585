import { StarIcon } from "@chakra-ui/icons";
import { LinkOverlay } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { setLike } from "../../utils/requestApi/wallet";

function LikeIcon({ id, address, status, size }) {
  const [isLiked, setIsLiked] = useState();

  const handleLike = async () => {
    const response = await setLike({ id, address });
    setIsLiked(response.data.data.is_liked);
  }

  useEffect(() => {
    setIsLiked(status)
  }, [status]);

  return <StarIcon onClick={handleLike} color={isLiked ? 'yellow' : 'grey'} w={size || 6} h={size || 6} _hover={{ cursor: 'pointer' }} mb={1} />;
}

export default LikeIcon;