import { useWeb3React } from "@web3-react/core";
import { getUserProfile } from "../../utils/requestApi/profile";
import { getUserWallets } from "../../utils/requestApi/wallet";
import { WalletItem } from "../Wallets";
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink as ReachLink } from 'react-router-dom';
import { b64toBlob, shortAddress } from "../../utils/helper";
import * as RoutePaths from '../../utils/routings';

import './style.css';
import EditProfileModal from "./edit";
import FavoriteWallets from "./favorite";
import { Footer } from "../../components/Footer";
import { CustomButton } from "../../components/Button";


function ProfilePage() {
  const { address } = useParams();
  const { active, account } = useWeb3React();
  const [profile, setProfile] = useState();
  const [wallets, setWallets] = useState();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [editNickname, setEditNickname] = useState(false);

  const isOwner = address === profile?.address;
  console.log('isOwner', isOwner);
  console.log('profile', profile);

  useEffect(() => {
    const getWalletList = async () => {
      try {
        const response = await getUserWallets({ address });
        console.log('wallets', response.data);
        setWallets(response.data);
      } catch (e) {
        console.log('error', e);
      }
    };

    getWalletList();
  }, []);

  useEffect(() => {
    console.log(address);
  }, []);

  useEffect(() => {
    let nav_items = document.querySelectorAll('[data-target]');
    let contents = document.querySelectorAll('[data-category]');
    for (var item of nav_items) {
      item.addEventListener('click', function () {
        for (var item of nav_items) {
          item.classList.remove('active');
        }
        for (item of contents) {
          item.hidden = true;
        }
        this.classList.add('active');
        let content = document.querySelector(`[data-category="${this.dataset.target}"]`);
        content.hidden = false;
      });
    }
  }, []);

  const getProfileInfo = async () => {
    const response = await getUserProfile({ address });
    setProfile(response.data);
    console.log('response', response);
  }

  useEffect(() => {
    getProfileInfo();
    // setProfile(profile);
  }, []);

  return <>

<div className="" style={{position:'absolute', left:0, top:0, height:'100%', width: '100%', overflow:'hidden',zIndex:0}}>

          
{/* <div className="gradient-2 gradient" style={{bottom:'70%', top:'auto'}}></div> */}
{/* <div className="gradient-1 gradient" style={{bottom:'70%', top:'auto'}}></div> */}
{/* <div className="gradient-5 gradient" style={{bottom:'72%', top:'auto'}}></div> */}

<div className="gradient-2 gradient" style={{bottom:'70%', top:'auto'}}></div>
<div className="gradient-1 gradient" style={{bottom:'70%', top:'auto'}}></div>
<div className="gradient-5 gradient" style={{bottom:'72%', top:'auto'}}></div>
</div>
    {editNickname && profile && (
      <EditProfileModal
        onClose={() => setEditNickname(false)}
        isOpen={editNickname}
        address={address}
        setNickname={setProfile}
        profile={profile}
        getProfileInfo={getProfileInfo}
        currentNickname={profile.nickname}
      />
    )}
    <div className="profile">
      <div className="container">
        <div className="profile__body">
          <Heading textAlign="center" fontWeight={800} pt={10}>
            {isOwner ? 'My profile' : 'Profile'}
          </Heading>

          <Box 
          className="container"
          // maxW="870px" 
            margin="0 auto" pb={10}>
            <Flex pt={6} pb={6}>
              <div className="info-wrapper">
                <Image
                  w={'96px'}
                  h={'96px'}
                  borderRadius={6}
                  src={`${profile &&
                    profile.avatar && profile.avatar_type
                    ? `data:${profile.avatar_type};base64,` + profile.avatar
                    : 'https://cdn.fb.ru/user_ava/198590/main/f.jpg?2907164'
                    }`}
                  mr={10}
                />
                <Box display={'flex'} flexFlow="row wrap" alignItems={'start'}>
                  {!isOwner && (
                    <Text fontWeight={800} fontSize={18} textOverflow="hidden">
                      {profile && profile.nickname && profile.nickname !== ''
                        ? `@${profile.nickname}`
                        : isMobile
                          ? shortAddress(address)
                          : address}{' '}
                    </Text>
                  )}

                  {isOwner && (
                    <>
                      <Flex flexFlow={"column"}>
                        <Text fontWeight={800} fontSize={18} textOverflow="hidden">
                          {profile && profile.nickname && profile.nickname !== ''
                            ? `@${profile.nickname}`
                            : isMobile
                              ? shortAddress(address)
                              : address}{' '}
                          {isOwner && <EditIcon onClick={() => setEditNickname(true)} cursor={'pointer'} mb={'5px'} ml={2} />}
                        </Text>
                        <Flex pt={4} display={{ base: 'none', lg: 'block' }}>
                          {/* <Button
                            fontWeight={700}
                            as={ReachLink}
                            to={RoutePaths.CREATE_WALLET}
                            colorScheme="teal"
                            background={"#ffffff"}
                            variant="solid"
                            color="white"
                            mr={4}
                            _hover={{ background: '#ffffff', opacity: 0.9 }}
                            _active={{ background: '#ffffff', opacity:  0.9 }}
                            leftIcon={<AddIcon size="10px" />}
                          >
                            Create DAO
                          </Button> */}
                          <CustomButton
                            props={{
                              label:<>
                                <AddIcon size="10px" /> Create DAO
                              </>,
                              theme: 'primary',
                              isLink: true,
                              onClick: RoutePaths.CREATE_WALLET
                            }}
                          />
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Box>
              </div>
            </Flex>
          </Box>

          <div className="navigation">
            <div className="container">
              <div className="navigation__body">
                <ul className="navigation__items">
                  <li className="navigation__item active" data-target="wallets">
                    Wallets
                  </li>
                  <li className="navigation__item " data-target="favorites">
                    Favorites
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="content profile-no-data_container" >
            <div className="container">
              <div className="content__body">
                <div className="profile-wallets" data-category="wallets">
                  <div className="wallets__list">
                    {wallets && wallets.map((item, index) => (
                      <WalletItem data={item} key={item.id} showLikeIcon={false} />
                    ))}
                    {wallets && wallets.length === 0 && <div className="no-data_container profile-no-data_container">
                    <svg width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="71" cy="71" r="71" fill="rgba(53, 156, 251, 0)" stroke='#fff' strokeWidth={2} fillOpacity="1"/>
                      <path d="M40 42.5H57L63 47.5H98.5C102.918 47.5 106.5 51.0817 106.5 55.5V56.5V65.5H56L43 100H40C36.6863 100 34 97.3137 34 94V48.5C34 45.1863 36.6863 42.5 40 42.5Z" fillOpacity="0" fill="white"/>
                      <path d="M106.632 65.2V53.4588C106.632 50.1451 103.946 47.4588 100.632 47.4588H62.6437L59.5311 44.3446C58.0307 42.8434 55.9953 42 53.8728 42H40C36.6863 42 34 44.6863 34 48V93C34 96.866 37.134 100 41 100H42.8659M106.632 65.2H121.6C122.286 65.2 122.768 65.8753 122.546 66.5244L111.992 97.2976C111.438 98.9142 109.917 100 108.208 100H42.8659M106.632 65.2H58.6026C56.9319 65.2 55.4371 66.2385 54.8541 67.8042L42.8659 100" stroke="#ffffff" strokeWidth="2"/>
                    </svg>
                    No data
                    </div>}
                  </div>
                </div>

                <div className="favorites" data-category="favorites" hidden>
                  <FavoriteWallets />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>;
}

export default ProfilePage;