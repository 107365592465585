import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import Error from "../../../ui-kit/Error";
import CustomInput from "../../../ui-kit/Input";
import { addWalletCategory, updateWalletCategory } from "../../../utils/requestApi/wallet";

const ActionCateforyForm = ({ id, name, updateCategoryList }) => {
  const isAddMode = !id;
  const [formData, setFormData] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialValues = {
    name: name
  };

  const onSubmitHandler = useCallback((values, actions) => {
    setFormData({ values, actions });
  }, []);


  const handleCategory = async () => {
    const { name } = formData.values;
    try {
      const method = isAddMode ? addWalletCategory({ name }) : updateWalletCategory({ id, name });
      const response = await method;

      // console.log('response', response);
      if (response) {
        formData.actions.resetForm();
        formData.actions.setSubmitting(false);
        updateCategoryList();
        onClose();
      }
    } catch (e) {
      console.log('error', e);
      onError();
    }
  };

  const onError = () => {
    formData.actions.setSubmitting(false);
  };

  const handleUpdate = async () => {
    const response = await updateWalletCategory(id);
  }

  useEffect(() => {
    if (formData !== '') handleCategory()
  }, [formData]);

  return (
    <>
      {isAddMode ? <Button background={"#ffffff"} colorScheme="teal" color={"black"}
        variant="solid" onClick={onOpen}>Add category</Button> : <EditIcon onClick={onOpen} cursor="pointer" />}

      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered background={"#ffffff"}>
        <ModalOverlay />
        <ModalContent background={"#29292E"}>
          <ModalHeader color={"#fff"}>Add category </ModalHeader>
          <ModalCloseButton color={"white"} />
          <ModalBody pb={"40px"}>
            <Formik
              initialValues={initialValues}
              // validationSchema={TokenPriceSchema}
              validateOnChange={false}
              onSubmit={onSubmitHandler}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <>
                  <form onSubmit={handleSubmit} className="form form__token">
                    <div className="form__body form__token-price" style={{paddingBottom:0}}>
                      <Field
                        id="name"
                        component={CustomInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="name"
                        value={values.name}
                        autoComplete="off"
                        label="Name"
                        placeholder="Fill name category"
                      />
                      {errors.name && touched.name && <Error>{errors.name}</Error>}

                      <Button
                        height={'40px'}
                        id="token"
                        type="submit"
                        disabled={isSubmitting}
                        variant="solid"
                        bg="green.500"
                        colorScheme="black"
                        fontWeight={700}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                        display="flex"
                        align-items="center"
                        justify-content=" center"
                        background="#ffffff"
                        color="#000"
                        margin-top="60px"
                        borderRadius="100px;"
                        className="form__button icon-rorschach"
                      >
                        {isAddMode ? 'Create' : 'Update'}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ActionCateforyForm;