import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserFavoriteWallets } from "../../utils/requestApi/wallet";
import { WalletItem } from "../Wallets";

function FavoriteWallets() {
  const { address } = useParams();
  const [wallets, setWallets] = useState();

  useEffect(() => {
    const getFavoriteWalletList = async () => {
      try {
        const response = await getUserFavoriteWallets({ address });
        console.log('wallets', response.data);
        setWallets(response.data);
      } catch (e) {
        console.log('error', e);
      }
    };

    getFavoriteWalletList();
  }, []);

  return (
    <>
      <div className="wallets__list">
        {wallets && wallets.map((item) => (
          <WalletItem data={item} key={item.id} showLikeIcon={true} />
        ))}
      </div>
      {wallets && wallets.length === 0 && <div className="no-data_container profile-no-data_container">
        <svg width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="71" cy="71" r="71" fill="rgba(53, 156, 251, 0)" stroke='#fff' strokeWidth={2} fillOpacity="1"/>
          <path d="M40 42.5H57L63 47.5H98.5C102.918 47.5 106.5 51.0817 106.5 55.5V56.5V65.5H56L43 100H40C36.6863 100 34 97.3137 34 94V48.5C34 45.1863 36.6863 42.5 40 42.5Z" fillOpacity="0" fill="white"/>
          <path d="M106.632 65.2V53.4588C106.632 50.1451 103.946 47.4588 100.632 47.4588H62.6437L59.5311 44.3446C58.0307 42.8434 55.9953 42 53.8728 42H40C36.6863 42 34 44.6863 34 48V93C34 96.866 37.134 100 41 100H42.8659M106.632 65.2H121.6C122.286 65.2 122.768 65.8753 122.546 66.5244L111.992 97.2976C111.438 98.9142 109.917 100 108.208 100H42.8659M106.632 65.2H58.6026C56.9319 65.2 55.4371 66.2385 54.8541 67.8042L42.8659 100" stroke="#ffffff" strokeWidth="2"/>
        </svg>
        No data
      </div>}
    </>
  );
}

export default FavoriteWallets;