import React, { useState, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import { ContributionSchema } from './validation';
import { Button } from '@chakra-ui/react';
import CustomInput from '../../ui-kit/Input/index';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { toHex } from '../../utils/helper';
import { CustomButton } from '../../components/Button';
import styles from './style.module.css';

function ContributionForm({ toAddress, getWalletInfo }) {
  const [formData, setFormData] = useState('');
  const { account, library, chainId, } = useWeb3React();

  const onSubmitHandler = React.useCallback((values, actions) => {
    setFormData({ values, actions });
  }, []);

  const sendTransaction = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const signer = provider.getSigner();

    const tx = {
      from: account,
      to: toAddress,
      value: ethers.utils.parseEther(formData.values.value),
      // nonce: window.ethersProvider.getTransactionCount(account, 'latest'),
      gasLimit: ethers.utils.hexlify(10000),
      gasPrice: ethers.utils.hexlify(parseInt(await provider.getGasPrice()))
    };

    console.log('tx', tx);
    try {
      await signer.sendTransaction(tx);
      formData.actions.setSubmitting(false);
      getWalletInfo();
    } catch (e) {
      formData.actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    if (formData !== '') {
      sendTransaction();
    }
  }, [formData]);

  const handleChangeNetwork = async (network, handleSubmit) => {
    try {
      await library.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHex(network) }]
      });
      handleSubmit();
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: 'wallet_addEthereumChain'
            // params: [networkParams[toHex(network)]],
          });
        } catch (error) {
          console.log('error', error);
        }
      }
    }
  };

  return (
    <>
      <div className="contribution">
        {/* <div className="container"> */}
          <Formik
            enableReinitialize
            validationSchema={ContributionSchema}
            initialValues={{ value: '0.001' }}
            validate={(values) => {
              const errors = {};
              if (!values.value) {
                errors.value = 'Required';
              }

              return errors;
            }}
            onSubmit={onSubmitHandler}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <>
                <Form onSubmit={handleSubmit} className="contribution__body">
                  <h2 className="contribution__title">Make a Contribution:</h2>
                  <div className="contribution__block">
                    <Field
                      id="value"
                      component={CustomInput}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      name="value"
                      value={values.value}
                      autoComplete="off"
                      label="Contribution"
                      classNameLabel="contribution__label"
                      classNameInput="contribution__input"
                      style={{
                        width: '100%',
                        marginBottom: '0px',
                        fontFamily: 'Satoshi',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#FFFFFF',
                        padding: '12px 24px',
                        background: '#515156',
                        color: '#FFFFFF',
                        marginTop: '4px',
                        border: '0px solid #515156',
                        borderRadius: '10px',
                        height: '56px'
                      }}
                    />
                    {/* <div className="contribution__currency">ETH</div> */}
                    <CustomButton
                        props={{
                          label : "ETH",
                          theme : "white",
                          className: styles.ethBtn,
                          disabled : true,
                          type: "main",
                          isLink: true,
                        }}
                      />
                    {chainId === 1 ? (
                      // <Button
                      //   // ml={4}
                      //   // mr={4}
                      //   type="submit"
                      //   disabled={isSubmitting}
                      //   // colorScheme="black"
                      //   pr="52px"
                      //   pl="24px"
                      //   isLoading={isSubmitting}
                      //   loadingText="Submitting"
                      //   h="52px"
                      //   borderRadius="100px"
                      //   className="contribution__btn icon-neuron active"
                      // // ma={'8px 52px 8px 24px'}
                      // >
                      //   Contribute
                      // </Button>
                       <CustomButton
                        props={{
                          label : "Contribute",
                          theme : "primary",
                          type: "main"
                        }}
                      />
                    ) : (
                      // <button
                      //   // type="submit"
                      //   className="contribution__btn icon-neuron active"
                      //   onClick={() => handleChangeNetwork(1, handleSubmit)}
                      // >
                      //   Contribute
                      // </button>
                      <CustomButton
                        props={{
                          label : "Contribute",
                          theme : "white",
                          type: "main",
                          onClick: () => handleChangeNetwork(1, handleSubmit)
                        }}
                      />
                      // <Button ml={4} mr={4} onClick={() => handleChangeNetwork(1, handleSubmit)}>
                      //   Contribute
                      // </Button>
                    )}



                    {/* <button type="submit" className="contribution__btn icon-neuron active">
                      Contribute
                    </button> */}
                  </div>
                </Form>
              </>
            )}
          </Formik>
          {/* <form className="contribution__body">
            <h2 className="contribution__title">Make your contribution:</h2>
            <div className="contribution__block">
              <label for="" className="contribution__label">
                Contribution
                <input
                  type="text"
                  className="contribution__input"
                  value="100"
                  placeholder="100"
                />
              </label>
              <button className="contribution__btn icon-tablet ">Coins</button>
              <button className="contribution__btn icon-neuron active">
                Contribute
              </button>
            </div>
          </form> */}
        {/* </div> */}
      </div>
    </>
  );
}

export default ContributionForm;
