import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { CREATE_WALLET, WALLETS } from '../../utils/routings';

import Spline from '@splinetool/react-spline';
import './style.css';
import { Link } from 'react-router-dom';



function Home() {
  const { active } = useWeb3React();

  useEffect(() => {
    //расчет ширины для текста
    if (window.screen.width > 780) {
      var title = document.querySelector('.main__title');
      title.style.width = (window.screen.width * 46) / 100 + 'px';
    }
  }, []);


  // useEffect(() => {
  //   const tickerWrappers = document.querySelectorAll('.viewport-scroll-text-wrapper-vertical');

  //   tickerWrappers.forEach((tickerWrapper, ix) => {
  //     const list = tickerWrapper.querySelector('ul.viewport-scroll-text-list-vertical');
  //     const clonedList = list.cloneNode(true);
  //     let listHeight = 10;

  //     list.querySelectorAll('li').forEach((item) => {
  //       listHeight += item.offsetHeight;
  //     });

  //     list.style.height = listHeight + 'px';
  //     clonedList.classList.add('cloned');
  //     tickerWrapper.appendChild(clonedList);

  //     let mod = 1;
  //     if (ix === 1) {
  //       mod = -1;
  //     } else if (ix === 2) {
  //       mod = 1.7;
  //     }
  //     listHeight = mod > 0 ? listHeight : -listHeight;

  //     // gsap
  //     const timeline = gsap.timeline({ repeat: -1 });
  //     const time = 1;

  //     timeline
  //       .fromTo(
  //         list,
  //         { rotation: 0.01, y: 0 },
  //         { force3D: true, y: -listHeight, ease: Power2.easeNone },
  //         0
  //       )
  //       .fromTo(
  //         clonedList,
  //         { rotation: 0.01, y: listHeight },
  //         { force3D: true, y: 0, ease: Power2.easeNone },
  //         0
  //       )
  //       .set(list, { force3D: true, rotation: 0.01, y: listHeight })
  //       .to(
  //         clonedList,
  //         { force3D: true, rotation: 0.01, y: -listHeight, ease: Power2.easeNone },
  //         time
  //       )
  //       .to(
  //         list,
  //         { force3D: true, rotation: 0.01, y: 0, ease: Power2.easeNone },
  //         time
  //       );
  //   });
  // }, []);


  return (
    <main className="main" >
      <div style={{position:'absolute', width:'99vw', height: '100%' , left:0,top:0, overflow:'hidden'}}>
        <div className="gradient-1"></div>
        <div className="gradient-1"></div>
        <div className="gradient-4"></div>
        <div className="gradient-5"></div>
        <div className="gradient-2"></div>
        <div className="gradient-3"></div>
      </div>
      


      <div className="container home-page_container">
       
        <div className="main__body">
          <h1 className="main__title">
            <span className="main__title-white banner_title_custom">DeSci DAO</span> <br />
            <span className="main__title-white banner_title_custom">Explorer</span>
          </h1>
          <h3 className="main__title-white banner_title_h3">
            A Decentralized DAO Explorer and analytics platform
          </h3>
          {/* <h3 className="main__subtitle">
            <span className="main__subtitle-logo">Stored on Archon.Network</span>
          </h3> */}
          <div className="main__btns">
            <Link to={WALLETS} className="main__btn border-around">
              Explore Wallets
            </Link>

            {active &&
              <a href={CREATE_WALLET} className="main__btn icon-brains">
                Add DAO{' '}
              </a>
            }

            {/* <Box>
              <GnosisTransaction />
            </Box> */}

          </div>
        </div>
        <div className="main__canvas">
          {/* <div className="" style={{background:'red',width: '100%', height: '100%'}}></div> */}
          {/* <video width="600" height="100%" autoplay="" loop muted playsinline> */}
          {/* <source src="https://assets-global.website-files.com/655a09c01f4650d9c5cdd3ab/65bcd23b75a694ecda6a25bb_header-animation-transcode.mp4" /> */}
            {/* <source src="https://assets-global.website-files.com/655a09c01f4650d9c5cdd3ab/65bcd23b75a694ecda6a25bb_header-animation-transcode.webm" /> */}
        {/* <source src="https://rotato.netlify.app/alpha-demo/movie-hevc.mov" type='video/mp4; codecs="hvc1"' /> */}
        {/* <source src="https://rotato.netlify.app/alpha-demo/movie-webm.webm" type="video/webm" /> */}
        {/* </video> */}
          {/* <video 
            width={'100%'}
            autoplay="true" loop muted playsinline="true" >
            <source src="https://assets-global.website-files.com/655a09c01f4650d9c5cdd3ab/65bcd23b75a694ecda6a25bb_header-animation-transcode.mp4" data-wf-ignore="true" />
            <source src="https://assets-global.website-files.com/655a09c01f4650d9c5cdd3ab/65bcd23b75a694ecda6a25bb_header-animation-transcode.webm" data-wf-ignore="true" />
          </video> */}
          <Spline scene="https://prod.spline.design/UdbBIbgULuw2GhPX/scene.splinecode" />
        </div>
      </div>
    </main>
  );
}

export default Home;
